import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';

import { FaArrowRight } from 'react-icons/fa';
import { publicContentService } from '../../../../services/providers/public-content';
import FormLoader from '../../../../components/Form/fragments/FormLoader';
import { getAssetUrl } from '../../../../helpers/strapiUtils';
import { NavLink } from 'react-router-dom';

import './style.scss';
import RichTextMarkdown from '../../../../components/Strapi/RichTextMarkdown';

const NewsList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  
  const limit = 5;

  const init = () => {
    getData();
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await publicContentService.news();
      const content = await response.json();
      if (content.data) {
        setData(content.data);
        setPaginatedData(content.data.slice(0,5));
        setMaxPage(Math.floor(Math.ceil(content.data?.length / limit)));
      }
      hideLoader();
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  };

  const loadMore = (data, page) => {
    setPage(2);
    setPaginatedData(data.slice(0, page * limit));
  };

  const hideLoader = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='hdb-news-list'>
      {isLoading ? <FormLoader /> : null}

      { data && data.length > 0
        ?
          <>
            <strong className='hdb-news-list__title'>
              Todas as Notícias
            </strong>

            <div className='hdb-news-list__list'>
              <div className='row'>
                {paginatedData.map(news => (
                  <div key={`news-${news.id}`} className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 hdb-news-list__item'>
                    <div className='hdb-card'>
                      <img src={getAssetUrl(news.attributes.imagemDeDestaque.imagem)} alt={news.attributes.titulo} />
                      <div className='hdb-card__body'>
                        <strong className='hdb-card__title'>
                          {news.attributes.titulo}
                        </strong>
                        <div className='hdb-card__description'>
                          <RichTextMarkdown markdown={news.attributes.resumo} />
                        </div>
                      </div>
                      <div className='hdb-card__actions'>
                        <NavLink to={`/portal-de-noticias/${news.attributes.slug}`} className='hdb-button hdb-button--transparent'>
                          <span>Saiba mais</span>
                          <FaArrowRight />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              { data.length > 5
                ?
                <button className='hdb-button hdb-button--transparent hdb-news-list__more' title='Carregar mais' onClick={() => {loadMore(data, page+1)}} disabled={page >= maxPage}>
                  <span>Carregar mais</span>
                </button>
                :
                null
              }
            </div>
          </>
        :
          null
      }
    </div>
  );
};

export default wrapper(NewsList, {
});

import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';

import { publicContentService  } from '../../services/providers/public-content';
import FormLoader from '../../components/Form/fragments/FormLoader';

import { ABOUT_THE_HUB_ROUTES } from './constants/routes';
import Banner from '../../components/Strapi/Banner';
import TextAndImageBlock from '../../components/Strapi/TextAndImageBlock';
import TextAndVideoBlock from '../../components/Strapi/TextAndVideoBlock';
import Partners from './fragments/Partners';
import TowardsTheFuture from '../../components/Strapi/TowardsTheFuture';
import HubContent from './fragments/HubContent';
import Breadcrumb from '../../components/Strapi/Breadcrumb';
import TitleSubtitleButton from "../../components/Strapi/TitleSubtitleButton";
import MembershipLevels from "./fragments/MembershipLevels"

const AboutTheHub = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const [breadcrumb] = useState([{
    name: 'Hub',
    link: '/hub'
  }]);

  const init = () => {
    getData();
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await publicContentService.aboutTheHub();
      const content = await response.json();
      if (content.data) {
        setData(content.data.attributes);
      }
      hideLoader();
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  };

  const hideLoader = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='hdb-about-the-hub'>
      {isLoading ? <FormLoader /> : null}
      
      <div className='hdb-blue-gradient-container'>
        <Breadcrumb routes={breadcrumb} />
        
        <Banner data={data?.banner} imageFull={true} />
        
        <div className='container'>
          <TextAndImageBlock data={data?.bloco01} imgAuto={true} />
          <TextAndImageBlock data={data?.bloco02} imgAuto={true} />
          <TextAndImageBlock data={data?.bloco03} imgAuto={true} />
          <TextAndVideoBlock data={data?.bloco04} />
        </div>
      </div>
      
      <HubContent title={data?.tituloConteudoHub} data={data?.iconesConteudo} />

      <div className='hdb-blue-gradient-container'>
        <div className='container'>
          <MembershipLevels data={data}/>
          
          { data?.parceiros
            ?
            <Partners data={data?.parceiros} />
            :
            null
          }
          
          <TowardsTheFuture title={data?.tituloOportunidade} description={data?.descricaoOportunidade} />

          <div className='mb-10'>
            <TitleSubtitleButton title={data?.tituloCadastro} subtitle={data?.textoCadastro} buttonText={data?.textoBotaoCadastro} buttonLink={data?.linkBotaoCadastro} align='center' size='xl' isRegisterButton={true} invertText={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default wrapper(AboutTheHub, {
  route: { path: ABOUT_THE_HUB_ROUTES.ABOUT_THE_HUB }
});

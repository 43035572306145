import React from 'react';

import {useLangContext} from 'components/Globalization';
import wrapper from 'components/Wrapper';

const FormLoader = ({message}) => {
  const translate = useLangContext();

  return (
    <div className="hdb-loader hdb-loader--active hdb-loader--solid">
      <span className='hdb-loader__spinner'></span>
      <span>
        { message
        ?
          message
        :
          translate('LOADING')
        }
      </span>
    </div>
  );
};

export default wrapper(FormLoader, {
  namespace: 'FORM_LOADING'
});

import * as Yup from 'yup';
import { useCallback, useState } from 'react';


const REQUIRED_FIELD = 'Campo obrigatório';

const useUserAccountPasswordValidation = () => {
  const [validation, setValidation] = useState({
    hasLowerCase: false,
    hasUpperCase: false,
    hasNumber: false,
    hasSpecialCharacter: false,
    minLength: false
  });

  const CreatePasswordSchema = useCallback(
    customValidation =>
      Yup.object().shape({
        password: Yup.string().required(REQUIRED_FIELD),
        password_confirmation: Yup.string()
          .required(REQUIRED_FIELD)
          .test(
            'confirmPassword',
            "A senha não confere",
            function validatePassword(value) {
              const { password } = this.parent;
              return password === value;
            }
          ),
        ...customValidation
      }),
    []
  );

  const onPasswordChangeHandler = useCallback(event => {
    const { value } = event.target;

    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialCharacter = /\W/.test(value);
    const minLength = value.length >= 8;

    setValidation({
      hasLowerCase,
      hasUpperCase,
      hasNumber,
      hasSpecialCharacter,
      minLength
    });
  }, []);

  return {
    validation,
    CreatePasswordSchema,
    onPasswordChangeHandler
  };
};

export default useUserAccountPasswordValidation;


/* eslint-disable react/prop-types */
import React from 'react';
import {Outlet} from "react-router-dom";

import wrapper from 'components/Wrapper';

const ErrorOutlet = () => {

  return (
    <>
      <Outlet />
    </>
  );
};

export default React.memo(wrapper(ErrorOutlet, {

}));

import React, { useState, useEffect } from 'react';

export const ToastError = ({ show, message }) => {
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    let timeoutId;

    if (show) {
      setIsVisible(true);
      timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [show]);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
  <div className="hdb-toast-container">
    <div className="hdb-toast hdb-toast--error">
      <div className="hdb-toast__icon">
        <i className="hdb-icon hdb-icon--times-circle" />
      </div>
      <div className="hdb-toast__content">
        {message}
      </div>
      <button
        type="button"
        className="hdb-toast__close"
        onClick={handleClose}
      >
        <i className="hdb-icon hdb-icon--close" />
      </button>
    </div>
  </div>

)
  );
};

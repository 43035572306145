import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';

import {ABOUT_THE_PROGRAM_ROUTES} from './constants/routes';
import { publicContentService  } from '../../services/providers/public-content';
import FormLoader from '../../components/Form/fragments/FormLoader';

import TitleSubtitleButton from "../../components/Strapi/TitleSubtitleButton";
import TextAndImageBlock from "../../components/Strapi/TextAndImageBlock";
import IconAndText from "../../components/Strapi/IconAndText";
import VideoOrImage from "../../components/Strapi/VideoOrImage";
import TowardsTheFuture from '../../components/Strapi/TowardsTheFuture';
import Breadcrumb from '../../components/Strapi/Breadcrumb';

import './style.scss';

const AboutTheProgram = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [breadcrumb] = useState([{
    name: 'O Programa',
    link: '/o-programa'
  }]);

  const init = () => {
    getData();
  };

  useEffect(() => {
    init();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await publicContentService.aboutTheProgram();
      const content = await response.json();
      if (content.data) {
        setData(content.data.attributes);
      }
      hideLoader();
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  };

  const hideLoader = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='hdb-about-the-program'>
      {isLoading ? <FormLoader /> : null}
      
      <div className='hdb-blue-gradient-container'>
        <Breadcrumb routes={breadcrumb} />
        
        <div className='container'>
          <TitleSubtitleButton title={data?.titulo} subtitle={data?.subtitulo} buttonText={data?.textoBotaoInscricao} align='center' isRegisterButton={true} />

          <div className='hdb-about-the-program__video'>
            { data?.videoDestaque
              ?
              <VideoOrImage file={data.videoDestaque} />
              :
              null
            }
          </div>

          <TextAndImageBlock data={data?.bloco01} />

          <TextAndImageBlock data={data?.bloco02} imgAuto={true} />

          <div className="hdb-about-the-program__opportunities">
            <strong className='hdb-about-the-program__opportunities-title'>
              {data?.titulo}
            </strong>
            <IconAndText data={data?.oportunidades} />
          </div>
          
          <TowardsTheFuture title={data?.bloco03.titulo} description={data?.bloco03.descricao} image={data?.bloco03.imagem} />
        </div>
      </div>
    </div>
  );
};

export default wrapper(AboutTheProgram, {
  route: { path: ABOUT_THE_PROGRAM_ROUTES.ABOUT_THE_PROGRAM }
});

/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import wrapper from 'components/Wrapper';

import './style.scss';
import PropTypes from 'prop-types';
import {FaCircle} from 'react-icons/fa';

const MembershipLevels = ({ data }) => {
  
  let viewportWidth = 0;
  
  const COLORS = {
    LILAC: 'lilac',
    ORANGE: 'orange',
    GREEN: 'green',
    BLUE: 'blue',
    MAGENTA: 'magenta',
  };
  
  const init = () => {
    if (data && window.innerWidth < 768) {
      handleTabs();
    }
    
    window.addEventListener("resize", handleTabs);
  };
  
  const handleTabs = () => {
    if (data && viewportWidth !== window.innerWidth) {
      viewportWidth = window.innerWidth;
      
      data.formasDeAdesao?.forEach((formaAdesao, i) => {
        const tabClass = `.hdb-membership-levels__tab-check-${i}`;
        const tabs = document.querySelectorAll(tabClass);

        for (let j = 0; j < tabs.length; j++) {
          tabs[j].checked = false;
          
          if (data && window.innerWidth < 768) {
            convertRadioToCheckBox(i ,j, tabs[j]);
          } else {
            convertCheckBoxToRadio(i ,j, tabs[j]);
            if (j === 0) {
              tabs[j].checked = true;
            }
          }
        }
      });
    }
  };
  
  const convertRadioToCheckBox = (groupIndex, tabIndex, tab) => {
    if (tab.type !== 'checkbox') {
      tab.type = 'checkbox';
      tab.name = `tab-${groupIndex}-${tabIndex}`;
    }
  };

  const convertCheckBoxToRadio = (groupIndex, tabIndex, tab) => {
    if (tab.type !== 'radio') {
      tab.type = 'radio';
      tab.name = `tab-${groupIndex}`;
    }
  };
  
  const getColor = (color) => {
    let c = '';
    
    switch (color) {
      case 'Lilás':
        c = COLORS.LILAC
        break;
      case 'Laranja':
        c = COLORS.ORANGE
        break;
      case 'Verde':
        c = COLORS.GREEN
        break;
      case 'Azul':
        c = COLORS.BLUE
        break;
      case 'Magenta':
        c = COLORS.MAGENTA
        break;
      default:
        c = COLORS.LILAC
        break;
    }
    
    return c;
  };
  
  const getMembershipForms = (mf) => {
    return mf?.map((level, levelIndex) => {
      return (
        <div key={`ml-${levelIndex}`} className='hdb-membership-levels__tabs-container'>
          <strong>{level.tituloTipoDeUso}</strong>
          <div className='hdb-membership-levels__tabs'>
            { getMembershipProfile(level.perfilAdesao, levelIndex) }
          </div>
        </div>
      )
    })
  };

  const getMembershipProfile = (membershipProfile, levelIndex) => {
    return membershipProfile?.map((profile, profileIndex) => {
      return (
        <>
          <input type='radio' className={`hdb-membership-levels__tab-check-${levelIndex}`} name={`tab-${levelIndex}`}
                 id={`tab-${levelIndex}-${profileIndex}`} defaultChecked={profileIndex === 0} />
          <label htmlFor={`tab-${levelIndex}-${profileIndex}`}
                 className={`hdb-membership-levels__tab-label hdb-membership-levels__tab-label--${getColor(profile?.cor)}`}>
            {profile?.titulo}
          </label>
          <div className={`hdb-membership-levels__tab hdb-membership-levels__tab--${getColor(profile?.cor)}`}>
            <div className='hdb-membership-levels__tab-content'>
              <div className='row'>
                <div className='col-12'>
                  <p>
                    {profile?.descricao}
                  </p>

                  <hr />

                  <div className='row'>
                    { getProfileGroupContent(profile.grupoConteudo) }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
    })
  };

  const getProfileGroupContent = (groupContent) => {
    return groupContent?.map((item, i) => {
      return (
        <div key={`gc-${item.id}`}
             className={`${item.titulo ? 'col-12 col-sm-12 col-md-12 col-lg-4 mb-6' : 'row'}`}>
          { item.titulo
            ?
            <div className='hdb-membership-levels__tab-group-title'>
              {item.titulo}
            </div>
            :
            null
          }

          { getItemTopics(item.topicos, item.titulo) }
        </div>
      );
    })
  };

  const getItemTopics = (itemTopics, itemTitle) => {
    return itemTopics?.map((topic, i) => {
      return (
        <>
          {!itemTitle
            ?
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
              <div key={`tp-${topic.id}`} className='hdb-membership-levels__tab-item'>
                <FaCircle />
                <span>{topic.texto}</span>
              </div>
            </div>
            :
            <div key={`tp-${topic.id}`} className='hdb-membership-levels__tab-item'>
              <FaCircle />
              <span>{topic.texto}</span>
            </div>
          }
        </>
      );
    });
  };

  useEffect(() => {
    init();
  }, [data]);

  return (
    <div className='hdb-membership-levels'>
      <strong className='hdb-membership-levels__title'>
        {data?.tituloFormasDeAdesao}
      </strong>
      <p>{data?.subtituloFormasDeAdesao}</p>

      { data
        ?
        getMembershipForms(data.formasDeAdesao)
        :
        null
      }

      <div className='hdb-membership-levels__download'>
        <a className='hdb-button hdb-button--primary' href={`/assets/json${data?.beneficiosAdesao?.arquivo?.data?.attributes?.url}`} target='_blank'>
          {data?.beneficiosAdesao?.textoBotao}
        </a>
      </div>
    </div>
  );
};

MembershipLevels.defaultProps = {
  data: {
  }
};

MembershipLevels.propTypes = {
  data: PropTypes.object
};

export default wrapper(MembershipLevels, {
  namespace: 'MEMBERSHIP_LEVELS'
});

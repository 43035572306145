import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import './style.scss';
import { FaArrowLeft, FaChevronRight, FaHome } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import { isRoutePublic } from '../../../helpers/routeUtils';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../../modules/App/constants/routes';

function Breadcrumb({ routes, showBack, backUri, isAuthenticated = false }) {
  
  const location = useLocation();
  
  const [homePath, setHomePath] = useState(PUBLIC_ROUTES.HOME_PAGE);
  
  const init = () => {
    handleHomePath(false);
  };
  
  const handleHomePath = (isAuthenticated) => {
    if (isAuthenticated && !isRoutePublic(location.pathname)) {
      setHomePath(PRIVATE_ROUTES.WELCOME_PAGE);
    } else {
      setHomePath(PUBLIC_ROUTES.HOME_PAGE);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    handleHomePath(isAuthenticated);
  }, [isAuthenticated]);

  return (
    <>
      { routes && routes.length > 0
        ?
        <div className='hdb-breadcrumb'>
          <div className='container'>
            <div className='hdb-breadcrumb__list'>
              <NavLink to={homePath} className='hdb-breadcrumb__home-link'>
                <FaHome />
              </NavLink>

              {routes.map((route, index) => (
                <>
                  <span>
                    <span className='hdb-breadcrumb__chevron'>
                      <FaChevronRight />
                    </span>
                    { route?.link && routes.length > 1
                    ?
                      <NavLink to={route?.link}>
                        {route?.name}
                      </NavLink>
                    :
                      <span>
                        {route?.name}
                      </span>
                    }
                  </span>
                </>
              ))}
            </div>
            { showBack
              ?
              <NavLink to={backUri} className='hdb-button hdb-button--transparent hdb-breadcrumb__back'>
                <FaArrowLeft />
                <span>Voltar</span>
              </NavLink>
              :
              null
            }
          </div>
        </div>
        :
        null
      }
    </>
  );
}

Breadcrumb.defaultProps = {
  routes: [],
  showBack: false,
  backUri: '/'
};

Breadcrumb.propTypes = {
  routes: PropTypes.array,
  showBack: PropTypes.bool,
  backUri: PropTypes.string
};

export default Breadcrumb;


import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';

const Alert = ({ open, title, textBody, button , icon }) => {
  
  const modalClassActive = open ? 'hdb-modal-container--active' : null;

  return (
    <div className='hdb-modal-alert'>
      <div className={'hdb-modal-container ' + modalClassActive}>
        <div className="hdb-modal hdb-modal--success">
          <div className="hdb-modal__header">
            <strong className="hdb-modal__title">
              {title}
            </strong>
          </div>

          <div className="hdb-modal__body">
            {icon ? <img src={icon} height="84" width="84" alt="Ícone de alerta" className="hdb-modal__icon" /> : null}
            <p className="hdb-modal__text" dangerouslySetInnerHTML={{__html: textBody}} />
          </div>

          <div className="hdb-modal__actions">
            <div className='row'>
              <div className='col'>
                <NavLink to={button.onConfirm} className='hdb-button hdb-button--primary'>
                  <span>{button.name}</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
};

export default Alert;

import {Navigate, useRoutes} from "react-router-dom";


import { ADMIN_ROUTES, APP_ROUTES, PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../constants/routes';
import { ROLES } from '../../../User/constants/roles';
import HomePage from '../../../HomePage';
import NoAccess from '../../submodules/NoAccess';
import InitialPage from '../../../InitialPage';
import AboutTheProgram from "../../../AboutTheProgram";
import WelcomePage from '../../../User/submodules/WelcomePage';
import AboutTraining from '../../../AboutTraining';
import AboutTheHub from '../../../AboutTheHub';
import UserAccountPage from '../../../User/submodules/UpdateUserAccount';
import News from '../../../News';
import NewsDetail from '../../../News/submodules/NewsDetail';
import PublicRoutesOutlet from '../../outlets/PublicRoutesOutlet';
import AuthOutlet from '../../../Auth/outlets/AuthOutlet';
import PrivateRoutesOutlet from '../../outlets/PrivateRoutesOutlet';
import ErrorOutlet from '../../outlets/ErrorOutlet';
import PrivacyNotice from '../../../PrivacyNotice';
import ViewAccount from '../../../User/submodules/ViewUserAccount';
import ChangePasswordUserAccount from '../../../User/submodules/ChangePasswordUserAccount';
import Login from '../../../Auth/submodules/Login';
import Logout from '../../../Auth/submodules/Logout';

const publicRoutes = [
  {
    element: <PublicRoutesOutlet />,
    children: [{
      element: <AuthOutlet />,
      children: [{
        path: PUBLIC_ROUTES.HOME_PAGE,
        element: <HomePage />
      }, {
        path: PUBLIC_ROUTES.ABOUT_THE_PROGRAM,
        element: <AboutTheProgram />
      }, {
        path: PUBLIC_ROUTES.ABOUT_TRAINING,
        element: <AboutTraining />
      }, {
        path: PUBLIC_ROUTES.ABOUT_THE_HUB,
        element: <AboutTheHub />
      }, {
        path: PUBLIC_ROUTES.NEWS,
        element: <News />
      }, {
        path: PUBLIC_ROUTES.NEWS_DETAIL,
        element: <NewsDetail />
      }, {
        path: PUBLIC_ROUTES.PRIVACY_NOTICE,
        element: <PrivacyNotice />
      }, {
        path: PUBLIC_ROUTES.LOGIN,
        element: <Login />
      }, {
        path: PUBLIC_ROUTES.LOGOUT,
        element: <Logout />
      }, {
        path: PRIVATE_ROUTES.WELCOME_PAGE,
        element: <WelcomePage />
      }]
    }]
  }, {
    path: "*",
    element: <Navigate to={PUBLIC_ROUTES.HOME_PAGE} replace />
  }
];

const adminRouteProvider = {
  routes: [
       ...publicRoutes,
    {
      element: <PrivateRoutesOutlet />,
      children: [{
        path: ADMIN_ROUTES.INITIAL_PAGE,
        element: <InitialPage />
      }]
    }, {
      path: "*",
      element: <Navigate to={PUBLIC_ROUTES.HOME_PAGE} replace />
    }
  ],
  links: [],
  menus: [],
};

const privateRouteProvider = {
  routes: [
    ...publicRoutes,
    {
      element: <PrivateRoutesOutlet />,
      children: [{
        element: <AuthOutlet/>,
        children: [
          {
            path: ADMIN_ROUTES.INITIAL_PAGE,
            element: <InitialPage />
          },
          {
            path: PRIVATE_ROUTES.CHANGE_PASSWORD,
            element: <ChangePasswordUserAccount />,
          },
          {
            path: PRIVATE_ROUTES.MY_PROFILE,
            element: <ViewAccount />
          },
          {
            path: PRIVATE_ROUTES.EDIT_PROFILE,
            element: <UserAccountPage />
          }
        ]
      }
      ]
    }, {
      path: "*",
      element: <Navigate to={PUBLIC_ROUTES.HOME_PAGE} replace />
    }
  ],
  links: [],
  menus: [],
};

const defaultRouteProvider = {
  routes: [
    ...publicRoutes,
    {
      element: <ErrorOutlet />,
      children: [{
        path: APP_ROUTES.NOT_ALLOWED,
        element: <NoAccess />
      }]
    }, {
      path: "*",
      element: <Navigate to={PUBLIC_ROUTES.HOME_PAGE} replace />
    }
  ],
  links: [],
  menus: [],
};

export const AppRouter = ({ role, isAuthenticated, meFailed, isWaitingGovBrLogout }) => {
  let currentRoutes = publicRoutes;

  if (isAuthenticated) {
    currentRoutes = privateRouteProvider.routes;
    
    if (role === ROLES.ROLE_ADMIN) {
      currentRoutes = adminRouteProvider.routes;
    }
  }

  return useRoutes(currentRoutes);
};
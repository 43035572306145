import React from 'react';

import PropTypes from 'prop-types';

import { FaCheck, FaCircle } from 'react-icons/fa';

import './style.scss';
import VideoOrImage from "../VideoOrImage";

function TextAndVideoBlock({ data }) {
  
  const VIDEO_POSITION = {
    LEFT: 'Esquerda',
    RIGHT: 'Direita'
  };

  const LIST_TYPE = {
    CHECK: 'Check',
    CIRCLE: 'Círculo'
  };
  
  return (
    <div className='hdb-text-video-block'>
      { data?.posicaoVideo == VIDEO_POSITION.LEFT
        ?
        <div className='hdb-text-video-block__left-video'>
          { data?.video
          ?
            <VideoOrImage file={data?.video} />
          :
            null
          }
        </div>
        :
        null
      }

      <div className='hdb-text-video-block__text'>
        <strong className='hdb-text-video-block__title'>
          {data?.titulo}
        </strong>
        
        { data?.descricao
          ?
          <span className='hdb-text-video-block__description' dangerouslySetInnerHTML={{__html: data?.descricao}}></span>
          :
          null
        }

        { data?.topicos
          ?
          <div className='hdb-text-video-block__checks'>
        
            { data?.topicos.topico.map(item => (
              <div key={`topico-${item.id}`} className='hdb-text-video-block__check-item'>
                { data?.topicos.iconeTopico === LIST_TYPE.CHECK
                  ?
                  <FaCheck />
                  :
                  null
                }
                
                { data?.topicos.iconeTopico === LIST_TYPE.CIRCLE
                  ?
                  <FaCircle />
                  :
                  null
                }
                <span>{item.texto}</span>
              </div>
            ))}
          </div>
          :
          null
        }
        
      </div>

      { data?.posicaoVideo == VIDEO_POSITION.RIGHT && data?.video
        ?
        <div className='hdb-text-video-block__right-video'>
          { data?.video
            ?
            <VideoOrImage file={data.video} />
            :
            null
          }
        </div>
        :
        null
      }
    </div>
  );
}

TextAndVideoBlock.defaultProps = {
  data: {}
};

TextAndVideoBlock.propTypes = {
  data: PropTypes.object
};

export default TextAndVideoBlock;

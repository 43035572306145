import {connect} from 'react-redux';

import {globalization} from '../Globalization';

const wrapper = (Component, { mapState, mapDispatch, namespace, route }) => {
  let component = Component;

  if (mapState || mapDispatch) {
    component = connect(mapState, mapDispatch)(component);
  }

  if (namespace) {
    component = globalization(component, namespace);
  }

  return component;
};

export default wrapper;

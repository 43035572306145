export const PUBLIC_CONTENT_API = {
  HEADER: '/assets/json/header.json',
  FOOTER: '/assets/json/footer.json',
  HOME: '/assets/json/home.json',
  FAQS: '/assets/json/faqs.json',
  FEATURE_NEWS: '/assets/json/destaques.json',
  NEWS: '/assets/json/noticias.json',
  ABOUT_THE_PROGRAM: '/assets/json/o-programa.json',
  ABOUT_TRAINING: '/assets/json/sobre-a-formacao.json',
  ABOUT_THE_HUB: '/assets/json/sobre-o-hub.json',
  PRIVACY_NOTICE: '/assets/json/aviso-de-privacidade.json'
};

import React from 'react';

import PropTypes from 'prop-types';

import { getAssetUrl } from '../../../helpers/strapiUtils';

import './style.scss';
import { FaCheck, FaCircle } from 'react-icons/fa';

function ImageAndTopics({ data, imgAuto }) {

  const IMAGE_POSITION = {
    LEFT: 'Esquerda',
    RIGHT: 'Direita'
  };
  
  const LIST_TYPE = {
    CHECK: 'Check',
    CIRCLE: 'Círculo'
  };

  return (
    <div className={`hdb-image-and-topics ${imgAuto ? 'hdb-image-and-topics--img-auto': null}`}>
      { data?.posicaoImagem == IMAGE_POSITION.LEFT
        ?
        <div className='hdb-image-and-topics__left-image'>
          <img src={getAssetUrl(data?.imagem)} />
        </div>
        :
        null
      }

      <div className='hdb-image-and-topics__text'>
        <strong className='hdb-image-and-topics__title'>
          {data?.titulo}
        </strong>
        <div className='hdb-image-and-topics__list'>
          { data?.topicos?.topico?.map(item => (
            <div key={`ntp-${item.id}`} className='hdb-image-and-topics__item'>
              { data?.topicos.iconeTopico === LIST_TYPE.CHECK
                ?
                <FaCheck />
                :
                null
              }

              { data?.topicos.iconeTopico === LIST_TYPE.CIRCLE
                ?
                <FaCircle />
                :
                null
              }
              
              <span>{item.texto}</span>
            </div>
          ))}
        </div>
      </div>

      { data?.posicaoImagem == IMAGE_POSITION.RIGHT
        ?
        <div className='hdb-image-and-topics__right-image'>
          <img src={getAssetUrl(data?.imagem)} />
        </div>
        :
        null
      }
    </div>
  );
}

ImageAndTopics.defaultProps = {
  data: {},
  imgAuto: false
};

ImageAndTopics.propTypes = {
  data: PropTypes.object,
  imgAuto: PropTypes.bool
};

export default ImageAndTopics;


import React from 'react';
import ReactDOM from 'react-dom';
import {Provider as ReduxProvider} from 'react-redux';

import 'assets/styles/scss/styles.scss';

import {ThemeProvider as SCThemeProvider} from 'styled-components';
import GlobalStyles from 'assets/styles/global';
import muiTheme from 'assets/styles/theme';

import {StylesProvider, ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';

import store from 'config/store/store';

import 'config/i18n';
import 'config/yup';

import NotificationProvider from 'modules/Notification/context/NotificationProvider';
import { BrowserRouter } from 'react-router-dom';
import App from 'modules/App';

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <MuiThemeProvider theme={muiTheme}>
            <SCThemeProvider theme={muiTheme}>
              <GlobalStyles />
              <NotificationProvider>
                <App />
              </NotificationProvider>
            </SCThemeProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </ReduxProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

export const addGoogleAnalytics = () => {
  if (window.env.GOOGLE_ANALYTICS_ENABLED && window.env.GOOGLE_ANALYTICS_ID) {
    const head = document.getElementsByTagName("head")[0];
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${window.env.GOOGLE_ANALYTICS_ID}`);
    scriptElement.onload = function() {
      window.dataLayer = window.dataLayer || [];

      /* eslint-disable no-undef */
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', window.env.GOOGLE_ANALYTICS_ID);
    }

    head.insertBefore(scriptElement, head.children[1]);
  }
};
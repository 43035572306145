import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';

import { FaArrowRight, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { publicContentService } from '../../../../services/providers/public-content';
import FormLoader from '../../../../components/Form/fragments/FormLoader';
import { getAssetUrl } from '../../../../helpers/strapiUtils';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import * as _ from 'lodash';

import './style.scss';
import RichTextMarkdown from '../../../../components/Strapi/RichTextMarkdown';

const NewsSlider = ({ title, showAll, isFeatureNews }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const init = () => {
    getData();
    
    window.addEventListener("resize", handleResize);
  };
  
  const handleResize = () => {
    if (window.matchMedia("(max-width: 575px)").matches) {
      initSlider(1);
    } else if (window.matchMedia("(max-width: 991px)").matches) {
      initSlider(2);
    } else {
      initSlider(3);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      let responseNews;
      let contentNews;

      const responseFeatureNews = await publicContentService.featureNews();
      const contentFeatureNews = await responseFeatureNews.json();
      
      if (!isFeatureNews || showAll) {
        responseNews = await publicContentService.news();
        contentNews = await responseNews.json();
      }
      
      if (contentFeatureNews.data || contentNews.data) {
        let newsArray = [
          ...contentFeatureNews.data,
          ...contentNews.data
        ];
        
        if (isFeatureNews && !showAll) {
          newsArray = contentFeatureNews.data.slice(3);
        }

        const sortedArray = _.orderBy(newsArray, [(obj) => new Date(obj.attributes.createdAt)], ['desc']);
        
        setData(sortedArray);
        initSlider(3);
      }
      hideLoader();
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  };
  
  const initSlider = (sliderItems) => {
    const slider = document.querySelector('.hdb-news-slider__slider');
    const sliderItem = slider.querySelector('.hdb-news-slider__item');
    const leftButton = document.querySelector('.hdb-news-slider__previous');
    const rightButton = document.querySelector('.hdb-news-slider__next');

    const sliderWidth = slider.offsetWidth;
    const sliderItemStyle = sliderItem.currentStyle || window.getComputedStyle(sliderItem)
    const sliderMarginRight = Number(sliderItemStyle.marginRight.match(/\d+/g)[0]);

    const itemCount = slider.querySelectorAll('.hdb-news-slider__item').length;

    let offset = 0;
    const maxX = -((itemCount / sliderItems) * sliderWidth + (sliderMarginRight * (itemCount / sliderItems)) - sliderWidth - sliderMarginRight);

    leftButton.addEventListener('click', function() {
      if (offset !== 0) {
        offset += sliderWidth + sliderMarginRight - 11;
        slider.style.transform = `translateX(${offset}px)`;
      }
    })

    rightButton.addEventListener('click', function() {
      if (offset !== maxX && (Math.abs(offset) + 11) < Math.abs(maxX)) {
        offset -= sliderWidth + sliderMarginRight - 11;
        slider.style.transform = `translateX(${offset}px)`;
      }
    })
  };

  const hideLoader = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='hdb-news-slider'>
      {isLoading ? <FormLoader /> : null}

      { data && data.length > 0
        ?
          <>
            <div className='hdb-news-slider__header'>
              <strong className='hdb-news-slider__title'>
                {title}
              </strong>

              <NavLink to={'/portal-de-noticias'} className='hdb-button hdb-button--transparent'>
                <span>Veja todas as notícias</span>
                <FaArrowRight />
              </NavLink>
            </div>

            <div className='hdb-news-slider__slider-wrapper'>
              <button className='hdb-news-slider__previous' disabled={data.length <= 3}>
                <FaChevronLeft />
              </button>
              <div className='hdb-news-slider__slider-items'>
                <ul className='hdb-news-slider__slider'>
                  {data.map(news => (
                    <li key={`fnc-${news.id}`} className='hdb-news-slider__item'>
                      <div className='hdb-card'>
                        <img src={getAssetUrl(news.attributes.imagemDeDestaque.imagem)} alt={news.attributes.titulo} />
                        <div className='hdb-card__body'>
                          <strong className='hdb-card__title'>
                            {news.attributes.titulo}
                          </strong>
                          <div className='hdb-card__description'>
                            <RichTextMarkdown markdown={news.attributes.resumo} />
                          </div>
                        </div>
                        <div className='hdb-card__actions'>
                          <NavLink to={`/portal-de-noticias/${news.attributes.slug}`} className='hdb-button hdb-button--transparent'>
                            <span>Saiba mais</span>
                            <FaArrowRight />
                          </NavLink>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <button className='hdb-news-slider__next' disabled={data.length <= 3}>
                <FaChevronRight />
              </button>
            </div>
          </>
        :
          null
      }
    </div>
  );
};

NewsSlider.defaultProps = {
  title: 'Destaques',
  showAll: false,
  isFeatureNews: true
};

NewsSlider.propTypes = {
  title: PropTypes.string,
  showAll: PropTypes.bool,
  isFeatureNews: PropTypes.bool
};

export default wrapper(NewsSlider, {
});

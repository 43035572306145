import {TokenManager} from 'config/auth/tokenManager';

import {LocalStorage} from '@app-starter/rnp-core';

const localStorage = new LocalStorage();

const tokenManager = new TokenManager({
  storage: localStorage
});

export { tokenManager };

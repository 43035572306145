/* eslint-disable react/prop-types */
import React from 'react';
import {Outlet} from "react-router-dom";
import Header from '../../fragments/Header';
import Footer from '../../fragments/Footer';
import wrapper from 'components/Wrapper';

const PrivateRoutesOutlet = () => {

  return (
    <>
    <Header />
     <main>
      <Outlet />
     </main>
      <Footer />
    </>
  );
};

export default React.memo(wrapper(PrivateRoutesOutlet, {

}));

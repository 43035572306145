import {http} from 'config/http/http';
import store from 'config/store/store';

import UserService from 'services/providers/user/service';
import UserUseCases from './useCases';

export const userService = new UserService({ http });

export const userUseCases = new UserUseCases({
  store,
  userService
});

/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';

import {AsyncLoad} from 'components/AsyncLoad';

import {authService, authUseCases} from 'services/providers/auth';
import FormLoader from '../../../../components/Form/fragments/FormLoader';
import { useLangContext } from '../../../../components/Globalization';
import { getHostUrl } from '../../../../helpers/routeUtils';

const AuthProvider = ({ children }) => {
  
  const translate = useLangContext();

  const [isLoading, setIsLoading] = useState(false);
  
  const init = () => {
    setIsLoading(true);
    
    authService.isAuthenticated().subscribe(isAuthenticated => {
      if (isAuthenticated) {
        authUseCases.continueOnPremiseSignIn(isAuthenticated);
      }
    });

    authService.onInitEvent().subscribe(isInitialized => {
      if (isInitialized) {
        setIsLoading(false);
      } else {
        // TODO: SE FALHAR => ESCONDER O BOTÃO DE ENTRAR
      }
    });

    authService.onSignOutEvent().subscribe(isAuthenticated => {
      if (isAuthenticated) {
        authUseCases.cleanSignOut();
        window.location.href = getHostUrl();
      }
    });
  };
  
  const restoreUserSessionPromise = useCallback(async () => {
    await authUseCases.restoreCurrentSession();
  }, []);

  useEffect(() => {
    init();
  }, []);

  return (
    <AsyncLoad promiseFn={restoreUserSessionPromise}>
      {isLoading ? <FormLoader message={translate('FORM_LOADING:INITIALIZING')} /> : null}
      {children}
    </AsyncLoad>
  );
};

export default AuthProvider;

/* eslint-disable no-return-await */

export default class ZipCodeUseCases {
  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   * @param {import('./service').default} props.zipCodeService
   */
  constructor({ store, zipCodeService }) {
    this.store = store;
    this.zipCodeService = zipCodeService;
  }

  async get(zipCode) {
    return await this.zipCodeService.get(zipCode);
  }
}



/* eslint-disable react/prop-types */
import React, {useState} from 'react';

import wrapper from 'components/Wrapper';
import {AUTH_ROUTES} from 'modules/Auth/constants/routes';
import {authUseCases} from 'services/providers/auth';
import { Navigate } from 'react-router-dom';
import FormLoader from '../../../../components/Form/fragments/FormLoader';
import { getHostUrl } from '../../../../helpers/routeUtils';
import { PRIVATE_ROUTES } from '../../../App/constants/routes';

const Login = ({ isAuthenticated }) => {
  const [isLoading] = useState(true);
  
  const signIn = () => {
    authUseCases.onPremiseSignIn(getHostUrl() + PRIVATE_ROUTES.WELCOME_PAGE);
  };

  if (isAuthenticated) {
    return <Navigate to={PRIVATE_ROUTES.WELCOME_PAGE} />;
  }

  signIn();
  
  return (
    <>
      {isLoading ? <FormLoader /> : null}
    </>
  );
};

export default wrapper(Login, {
  namespace: 'LOGIN',
  mapState: state => ({
    isAuthenticated: state.auth.isAuthenticated
  }),
  route: {
    path: AUTH_ROUTES.LOGIN
  }
});

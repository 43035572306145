import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { getAssetUrl } from '../../../helpers/strapiUtils';
import videoFrame from "../../../assets/img/hdb-video-frame-xl.png";
import {isImage, isVideo} from "../../../helpers/fileUtils";

import './style.scss';

function VideoOrImage({ file }) {
  
  const [height, setHeight] = useState('0px')

  const init = () => {
    window.addEventListener("resize", onResize);
    setTimeout(() => {
      onResize();
    }, 500);
  };
  
  const onResize = () => {
    const element = document.querySelector(`.hdb-video-or-image__video-frame`);
    if (element) {
      const width = element.clientWidth;
      setHeight(`${width * 0.5625}px`);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='hdb-video-or-image'>
      { isImage(getAssetUrl(file))
        ?
        <>
          <img className='hdb-video-or-image__img' src={getAssetUrl(file)} alt='Video Frame' />
        </>
        :
        null
      }
      
      { isVideo(getAssetUrl(file))
        ?
          <>
            <img className='hdb-video-or-image__video-background' src={videoFrame} alt='Video Frame' />
            <div className='hdb-video-or-image__video-frame' style={{height: height}}>
              <video controls>
                <source src={getAssetUrl(file)} type="video/mp4" />
                Seu navegador não suporta o player de vídeo.
              </video>
            </div>
          </>
        :
          null
      }
    </div>
  );
}

VideoOrImage.defaultProps = {
  file: null
};

VideoOrImage.propTypes = {
  file: PropTypes.object
};

export default VideoOrImage;


import * as Yup from 'yup';


const REQUIRED_FIELD = 'Campo obrigatório';
const REGEX_VALID_FORMAT_NAME = /\b[A-Za-zÄ-ÿ][A-Za-zÄ-ÿ]+,?(\s[A-Za-zÄ-ÿ][A-Za-zÄ-ÿ]{0,19}\b)+/;
const ALLOWED_STATES = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO", "Acre", "Alagoas", "Amazonas", "Amapá", "Bahia", "Ceará", "Distrito Federal", "Espírito Santo", "Goiás", "Maranhão", "Minas Gerais", "Mato Grosso do Sul", "Mato Grosso", "Pará", "Paraíba", "Pernambuco", "Piauí", "Paraná", "Rio de Janeiro", "Rio Grande do Norte", "Rondônia", "Roraima", "Rio Grande do Sul", "Santa Catarina", "Sergipe", "São Paulo", "Tocantins"];

class UserAccountFormSchema {
  static schema = Yup.object().shape({
    id: Yup.string().required(REQUIRED_FIELD),
    email: Yup.string().required(REQUIRED_FIELD),
    firstName: Yup.string().required(REQUIRED_FIELD),
    lastName: Yup.string().required(REQUIRED_FIELD),
    zipCode: Yup.string().required(REQUIRED_FIELD),
    birthdate: Yup.string().required(REQUIRED_FIELD),
    gender: Yup.string().required(REQUIRED_FIELD),
    city: Yup.string().required(REQUIRED_FIELD),
    documentNumber: Yup.string().required(REQUIRED_FIELD).matches(/\d{3}\.\d{3}\.\d{3}-\d{2}/,'Insira um formato válido de documento.'),
    fullName: Yup.string().required(REQUIRED_FIELD).matches(REGEX_VALID_FORMAT_NAME, 'Insira um formato válido.'),
    hasPC: Yup.string().required(REQUIRED_FIELD),
    hasSocialName: Yup.string().required(REQUIRED_FIELD),
    socialName: Yup.string().when('hasSocialName', {
      is: 'true',
      then: Yup.string().required(REQUIRED_FIELD),
    }),
    contactNumber: Yup.string().required(REQUIRED_FIELD),
    neighborhood: Yup.string().required(REQUIRED_FIELD),
    internetType: Yup.string().when('hasPC', {
      is: 'true',
      then: Yup.string().required(REQUIRED_FIELD),
    }),
    state: Yup.string().required(REQUIRED_FIELD).oneOf(ALLOWED_STATES, 'Campo obrigatório. Mínimo de 2 caracteres. Ex.: RJ, Rio de Janeiro, DF e etc...'),
    recoveryEmail: Yup.string().required(REQUIRED_FIELD),
    educationalLevel: Yup.string().required(REQUIRED_FIELD),
    accessibility: Yup.string().required(REQUIRED_FIELD)
  });
}
export default UserAccountFormSchema;

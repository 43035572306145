import React from 'react';

import wrapper from 'components/Wrapper';

import './style.scss';
import PropTypes from 'prop-types';
import { getAssetUrl } from '../../../../helpers/strapiUtils';

const Partners = ({ data }) => {

  return (
    <div className='hdb-partners'>
      <strong className='hdb-partners__title'>
        {data?.titulo}
      </strong>
      <div className='hdb-partners__list'>
        {data?.logoParceiro?.map(item => (
          <a key={`partners-${item.id}`} href={item.link} title={item.label}>
            <img src={getAssetUrl(item?.imagem)} />
          </a>
        ))}
      </div>
    </div>
  );
};

Partners.defaultProps = {
  data: {
  }
};

Partners.propTypes = {
  data: PropTypes.object
};

export default wrapper(Partners, {
});

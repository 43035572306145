import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import wrapper from 'components/Wrapper';

import FormLoader from '../../../../components/Form/fragments/FormLoader';
import { publicContentService } from '../../../../services/providers/public-content';
import FeatureNewsCarousel from '../../fragments/NewsSlider';

import { PUBLIC_ROUTES } from '../../../App/constants/routes';
import { getAssetUrl } from '../../../../helpers/strapiUtils';
import Breadcrumb from '../../../../components/Strapi/Breadcrumb';
import RichTextMarkdown from '../../../../components/Strapi/RichTextMarkdown';

import * as _ from 'lodash';

import './style.scss';

const NewsDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  
  const [breadcrumb, setBreadcrumb] = useState([]);
  
  const slug = params.slug;
  
  const init = () => {
    getNewsData();
  };

  const getNewsData = async () => {
    setIsLoading(true);
    try {
      const response = await publicContentService.news();
      const content = await response.json();
      if (content.data && content.data.length > 0) {
        filterData(content.data);
      } else {
        getFeatureNewsData();
      }
      hideLoader();
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  };

  const getFeatureNewsData = async () => {
    setIsLoading(true);
    try {
      const response = await publicContentService.featureNews();
      const content = await response.json();
      if (content.data && content.data.length > 0) {
        filterData(content.data);
      }
      hideLoader();
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  };
  
  const filterData = (data) => {
    const news = _.filter(data, function(n) {
      return n.attributes?.slug == slug;
    });
    if (news && news.length > 0) {
      setData(news[0]);
      setBreadcrumb([{
        name: 'Portal de Notícias',
        link: '/portal-de-noticias'
      }, {
        name: news[0].attributes?.titulo,
        link: null
      }]);
    } else {
      navigate(PUBLIC_ROUTES.NEWS);
    }
  };

  const hideLoader = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='hdb-news-detail'>
      {isLoading ? <FormLoader /> : null}

      <div className='hdb-blue-gradient-container'>
        <Breadcrumb routes={breadcrumb} showBack={true} backUri={PUBLIC_ROUTES.NEWS} />
        
        <div className='hdb-news-detail__banner'>
          <img src={getAssetUrl(data.attributes?.imagemDeDestaque?.imagem)} alt={data.attributes?.titulo} />
        </div>

        <div className='container'>
          <strong  className='hdb-news-detail__title'>
            {data.attributes?.titulo}
          </strong>
          <div className='hdb-news-detail__content'>
            <RichTextMarkdown markdown={data.attributes?.corpo} />
          </div>
          <FeatureNewsCarousel showAll={true} />
        </div>
      </div>
    </div>
  );
};

export default wrapper(NewsDetail, {
});

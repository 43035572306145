import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../modules/App/constants/routes';

export const isRoutePublic = (path) => {
  return Object.values(PUBLIC_ROUTES).includes(path) && !Object.values(PRIVATE_ROUTES).includes(path);
}

export const getHostUrl = () => {
  let hostUri = window.location.protocol + '//' + window.location.hostname;
  
  if (window.location.port) {
    hostUri += ':' + window.location.port;
  }
  
  return hostUri;
};
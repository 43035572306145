/* eslint-disable react/prop-types */
import React from 'react';

import wrapper from 'components/Wrapper';

import './style.scss';
import { FaArrowRight } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { getAssetUrl } from '../../../../helpers/strapiUtils';
import { authUseCases } from '../../../../services/providers/auth';
import { NavLink } from 'react-router-dom';

const LearningPath = ({ data }) => {

  const register = () => {
    authUseCases.register();
  };
  
  return (
    <div className='hdb-learning-path'>
      <div className='container'>
        <div className='hdb-learning-path__top'>
          <div className='hdb-learning-path__text'>
            <strong className='hdb-learning-path__title'>
              {data?.titulo}
            </strong>
            <strong className='hdb-learning-path__subtitle'>
              {data?.subtitulo}
            </strong>
            <p className='hdb-learning-path__description' dangerouslySetInnerHTML={{__html: data?.descricao}}></p>
          </div>
          <img src={getAssetUrl(data?.imagem)} alt='' />
        </div>
        <div className='hdb-learning-path__paths'>

          { data?.niveisDeAprendizado.map((item, index) => (
            <div key={`path-${item.id}`} className='hdb-learning-path__path'>
              <span className='hdb-learning-path__path-number'>{index+1}</span>
              <div className='hdb-learning-path__path-text'>
                <strong>{item.titulo}</strong>
                <p dangerouslySetInnerHTML={{__html: item.descricao}}></p>
              </div>
            </div>
          ))}

          <div className='hdb-learning-path__path hdb-learning-path__more-path'>
            <p>{data?.textoSaibaMais}</p>
            <NavLink to={'/formacao'} className='hdb-button hdb-button--primary'>
              <span>{data?.textoBotaoSaibaMais}</span>
              <FaArrowRight />
            </NavLink>
          </div>
        </div>
        <div className='hdb-learning-path__register'>
          <strong className='hdb-learning-path__register-title'>
            {data?.tituloInscrevase}
          </strong>
          <strong className='hdb-learning-path__register-text'>
            {data?.textoInscrevase}
          </strong>
          <button type='button' className='hdb-button hdb-button--primary hdb-button--xl' onClick={register}>
            {data?.textoBotaoInscrevase}
          </button>
        </div>
      </div>
    </div>
  );
};

LearningPath.defaultProps = {
  data: {
    niveisDeAprendizado: []
  }
};

LearningPath.propTypes = {
  data: PropTypes.object
};

export default wrapper(LearningPath, {
  namespace: 'LEARNING_PATH'
});

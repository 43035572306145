import React, { useEffect, useState } from 'react';
import './style.scss';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const DropdownMenu = ({user, options, isAuthenticated, showLogin, loginEnabled}) => {
  
  const [isOpen, setIsOpen] = useState(false);
  
  const init = () => {
    handleOutsideClick();
  };
  
  const handleOutsideClick = () => {
    const dropwdownMenuElement = document.querySelector('.hdb-dropdown-menu');
    
    document.addEventListener('click', event => {
      if (dropwdownMenuElement) {
        const isClickInside = dropwdownMenuElement.contains(event.target);

        if (!isClickInside) {
          setIsOpen(false);
        }
      }
    });
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getFormattedName = (user) => {
    let name = "Olá, " + user.firstName;
    
    if (user.socialName) {
      name = "Olá, " + user.socialName;
    }
    
    return name;
  };
  
  const showRegisterMenu = () => {
    return !isAuthenticated || !loginEnabled && options.registerMenu;
  };
  
  const showSignInMenu = () => {
    return !isAuthenticated && showLogin && loginEnabled && options.signInMenu;
  };

  const getMenuLink = (opt) => {
    if (opt.link) {
      return <NavLink to={opt.link} title={opt.text}>
        <span>{opt.text}</span>
        <i className='hdb-icon hdb-icon--chevron-right'></i>
      </NavLink>;
    }

    return <button type='button' onClick={opt.handleClick}>
      <span>{opt.text}</span>
      <i className='hdb-icon hdb-icon--chevron-right'></i>
    </button>;
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className={`hdb-dropdown-menu ${isAuthenticated ? 'hdb-dropdown-menu--show' : ''}`} onClick={toggleDropdown}>
      
      <div className='hdb-dropdown-menu__title'>
        <strong>
          { user
            ?
            getFormattedName(user)
            :
            'Menu'
          }
        </strong>
          { isOpen
            ?
            <i className='hdb-icon hdb-icon--caret-up'></i>
            :
            <i className='hdb-icon hdb-icon--caret-down'></i>
          }
      </div>

      { isOpen
        ?
        <div className='hdb-dropdown-menu__items'>
          { options.pageMenu
            ?
            <div className='hdb-dropdown-menu__page-menu'>
              <strong>
                Páginas
              </strong>

              <ul className='hdb-dropdown-menu__list'>
                { options.pageMenu.map((option, index) => (
                  <>
                    <li onClick={toggleDropdown} className='hdb-dropdown-menu__item'>
                      { getMenuLink(option) }
                    </li>
                  </>
                ))}
              </ul>
            </div>
            :
            null
          }

          <div className='hdb-dropdown-menu__user-menu'>
            <strong>
              Menu do usuário
            </strong>

            <ul className='hdb-dropdown-menu__list'>
              { isAuthenticated
                ?
                  options.userMenu?.map((option, index) => (
                    <>
                      <li onClick={toggleDropdown} className='hdb-dropdown-menu__item'>
                        { getMenuLink(option) }
                      </li>
                    </>
                  ))
                :
                null
              }

              { showRegisterMenu()
                ?
                <li onClick={toggleDropdown} className='hdb-dropdown-menu__item hdb-dropdown-menu__item--register'>
                  {
                    getMenuLink({
                      link: options.registerMenu.link,
                      text: options.registerMenu.text,
                      handleClick: options.registerMenu.handleClick 
                    })
                  }
                </li>
                :
                null
              }

              { showSignInMenu()
                ?
                <li onClick={toggleDropdown} className='hdb-dropdown-menu__item hdb-dropdown-menu__item--signIn'>
                  {
                    getMenuLink({
                      link: options.signInMenu.link,
                      text: options.signInMenu.text,
                      handleClick: options.signInMenu.handleClick
                    })
                  }
                </li>
                :
                null
              }
            </ul>
          </div>
        </div>
        :
        null
      }
    </div>
  );
};

DropdownMenu.defaultProps = {
  user: {},
  options: [],
  isAuthenticated: false,
  loginEnabled: false
};

DropdownMenu.propTypes = {
  user: PropTypes.object,
  options: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  loginEnabled: PropTypes.bool
};

export default DropdownMenu;

/* eslint-disable class-methods-use-this */
import {CookieStorage, LocalStorage} from '@app-starter/rnp-core';
import {RnpAuthService} from '@app-starter/rnp-auth-resolver';

import store from "../../../config/store/store";
import {authActions} from "../../../config/store/slices/auth.slice";
import jwt_decode from 'jwt-decode';
import { authService } from './index';

export default class AuthService {

  localStorage;

  cookieStorage;

  rnpAuthService;

  ME_FAILED_KEY = 'ME_FAILED';

  WAITING_GOV_BR_LOGOUT = 'WAITING_GOV_BR_LOGOUT';

  /**
   * @param {object} props
   // * @param {import('config/http/axios').Axios} props.http
   */
  constructor({http}) {
    this.http = http;
    this.localStorage = new LocalStorage();
    this.cookieStorage = new CookieStorage();
    
    this.rnpAuthService = new RnpAuthService(this.http, this.cookieStorage);
  }

  onPremiseSignIn(redirectUri) {
    this.rnpAuthService.signIn(redirectUri);
  }

  onPremiseSignOut() {
    authService.unsetMeFailedFlag();
    this.rnpAuthService.signOut();
  }

  register() {
    this.rnpAuthService.register();
  }

  async accessTokenLogin(jwt, token, username) {
    this.rnpAuthService.accessTokenSignIn(jwt, token, username);

    const session = await this.restoreUserSession();

    return {
      user: {
        username
      },
      session: session
    };
  }

  async signOut(redirectUri) {
    this.rnpAuthService.signOut(redirectUri);
  }

  govBrSignOut() {
    this.rnpAuthService.govBrsignOut();
  }

  clearAuthStorage() {
    this.rnpAuthService.clearAuthStorage();
  }

  async restoreUserSession() {
    return this.rnpAuthService.restoreSession();
  }

  registerListener(event, listener) {
    this.rnpAuthService.registerListener(event, listener);
  }

  registerListeners() {
    return false;
  }

  async getFullToken(code) {
    return this.rnpAuthService.getFullToken(code);
  }
  
  async getBearerToken() {
    return this.rnpAuthService.getBearerToken();
  }

  async getSubFromTokenAsUserId() {
    const token = await this.getBearerToken()
    return jwt_decode(token.replaceAll("Bearer", "")).sub
  }
  
  setMeFailedFlag() {
    this.localStorage.set(this.ME_FAILED_KEY, true);
    store.dispatch(authActions.meFailed(true));
  }

  unsetMeFailedFlag() {
    this.localStorage.remove(this.ME_FAILED_KEY);
    store.dispatch(authActions.meFailed(false));
  }

  getMeFailedFlag() {
    return this.localStorage.get(this.ME_FAILED_KEY);
  }

  setIsWaitingGovBrAuthLogout() {
    this.localStorage.set(this.WAITING_GOV_BR_LOGOUT, true);
  }

  unsetIsWaitingGovBrAuthLogout() {
    this.localStorage.remove(this.WAITING_GOV_BR_LOGOUT);
  }

  getIsWaitingGovBrFlag() {
    return this.localStorage.get(this.WAITING_GOV_BR_LOGOUT);
  }

  isAuthenticated() {
    return this.rnpAuthService.isAuthenticated();
  }

  onInitEvent() {
    return this.rnpAuthService.onInitEvent();
  }

  onSignOutEvent() {
    return this.rnpAuthService.onSignOutEvent();
  }
}

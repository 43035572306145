import {tokenManager} from 'config/auth';

import {RnpHttpClient} from '@app-starter/rnp-core';

const http = new RnpHttpClient({
  withCredentials: true,
  baseURL: window.env.API_URL
});

http.useInterceptor(async config => {
  const token = await tokenManager.getToken();

  if (token && !config.removeAuth) {
    config.headers.Authorization = token;
  }

  return config;
});

export { http };

import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';
import { useFormik } from 'formik';
import { userUseCases } from '../../../../services/providers/user';
import { authService } from '../../../../services/providers/auth';
import ConfirmDialog from '../../../../components/Dialog/ConfirmDialog';
import Alert from '../../../../components/Dialog/Alert';
import useUserAccountPasswordValidation from './schemas/password-user-account-form-schema';
import { ToastError } from '../../../../components/Toast';
import { PRIVATE_ROUTES } from '../../../App/constants/routes';
import { useLangContext } from '../../../../components/Globalization';
import { useNavigate } from 'react-router-dom';
import FormLoader from '../../../../components/Form/fragments/FormLoader';
import './style.scss';

const ChangePasswordUserAccount = ({isAuthenticated}) => {
  const navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalSuccessEdition, setShowModalSuccessEdition] = useState(false);
  const [error, setError] = useState(null);
  const translate = useLangContext();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const init = () => {
    getData();
  };


  useEffect(() => {
    if (isAuthenticated) {
      init();
    }
  },[isAuthenticated]);

  const { validation, onPasswordChangeHandler, CreatePasswordSchema } = useUserAccountPasswordValidation();
  
  const getData = async () => {
    setIsLoading(true);
    try {
      const userId = await authService.getSubFromTokenAsUserId();
      const response = await userUseCases.findUserAccount(userId)
      setIsLoading(false);
      setUserId(userId);
      await formik.setValues(response)
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };
  
  const formik = useFormik({
    initialValues: {},
    validationSchema: CreatePasswordSchema,
    onSubmit: () => {
      save()
    }
  });
  
  const save = () => {
    setIsLoading(true);
    try {
      userUseCases.forceResetPassword(userId, formik.values.password)
        .then(() => {
           setIsLoading(false);
           setShowModalSuccessEdition(true);
        })
        .catch((error) => {
          setIsLoading(false);
          const msg = translate('PASSWORD_ERROR');
          setError(msg)
          console.error(msg, error)
    });
    } finally {
      setIsLoading(false);
      setError(null)
    }
  }
  
  const onConfirmModelCancel = () => {
    setShowModalCancel(false);
    navigate(-1);
  }

  const onOpenPassword = () => {
    setPasswordVisible(!passwordVisible);
  }
  
  const getPasswordFieldType = (viewPassword) => {
    if (viewPassword) {
      return 'text';
    }
    
    return 'password';
  }
  
  const getValidationClass = (isValid) => {
    if (isValid) {
      return 'hdb-form__password-check--success';
    }
    
    return '';
  };

  return (
   <div className='hdb-change-password'>
     {isLoading ? <FormLoader /> : null}
     
     <ToastError show={error} message={error} />
     
     <ConfirmDialog
       open={showModalCancel}
       title={translate('MODAL_CANCEL_TITLE')}
       subtitle={translate('MODAL_CANCEL_SUBTITLE')}
       textBody={translate('MODAL_CANCEL_BODY')}
       buttonConfirm={{name: translate('MODAL_CANCEL_BUTTON_CONFIRM'), onConfirm: onConfirmModelCancel}}
       buttonCancel={{name: translate('MODAL_CANCEL_BUTTON_CANCEL'), onCancel: () => setShowModalCancel(false) }}
       icon='../../../../assets/img/hdb_warning_modal_icon.png'
     />
     
     <Alert
       open={showModalSuccessEdition}
       title={translate('ALERT_TITLE')}
       textBody={translate('ALERT_BODY')}
      icon='../../../../assets/img/hdb_circle_right.png' 
       button={{name: translate('ALERT_CONFIRM'), onConfirm: PRIVATE_ROUTES.WELCOME_PAGE}}
     />
     
     <div className='hdb-blue-gradient-container'>
       <div className='hdb-change-password__wrapper'>
         {!showModalSuccessEdition ? <div className="container">
           <div className='row'>
             <div className='col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3'>
               <div className='hdb-change-password__title'>
                 <h1>{translate('TITLE')}</h1>
               </div>
             </div>
           </div>

           <div className="row">
             <div className="col">
               <form className="hdb-change-password__form" onSubmit={formik.handleSubmit} >
                 <div className="row">
                   <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                     <div className="row">
                       <div className="col-12 col-md-12">
                         <div className="hdb-input-group">
                           <label htmlFor="password-new">E-mail</label>
                           <div className="hdb-input-group__icon-group">
                             <input
                               type="email"
                               name="email"
                               id="email"
                               value={formik.values.email}
                               className="hdb-input"
                               readOnly='true'
                               maxLength={24}
                             />
                           </div>
                         </div>
                       </div>
                       <div className="col-12 col-md-12">
                         <div className="hdb-input-group">
                           <label htmlFor="password-confirm">Crie sua senha</label>
                           <div className="hdb-input-group__icon-group">
                             <div className="hdb-input-group__prepend">
                               <i className="hdb-icon hdb-icon--lock" />
                             </div>
                             <input
                               type={getPasswordFieldType(passwordVisible)}
                               name="password"
                               id="password"
                               placeholder='Digite aqui...'
                               value={formik.values.password}
                               onChange={(event) => {
                                 formik.setFieldValue("password", event.target.value);
                                 onPasswordChangeHandler(event);
                               }}
                               onBlur={formik.handleBlur}
                               data-id="PASSWORD_CONFIRM"
                               className="hdb-input"
                               maxLength={24}
                             />
                             <div
                               className="hdb-input-group__append"
                               data-show-password="true"
                             >
                               <i className="hdb-icon hdb-icon--eye" onClick={onOpenPassword}/>
                             </div>
                           </div>
                         </div>
                       </div>
                       <div className="col-12 col-md-12">
                         <div className="hdb-input-group">
                           <label htmlFor="password-confirm">Repita sua senha</label>
                           <div className="hdb-input-group__icon-group">
                             <div className="hdb-input-group__prepend">
                               <i className="hdb-icon hdb-icon--lock" />
                             </div>
                             <input
                               type={getPasswordFieldType(passwordVisible)}
                               name="password-confirm"
                               id="password-confirm"
                               data-id="PASSWORD_CONFIRM"
                               placeholder='Digite aqui...'
                               value={formik.values.password_confirmation}
                               onChange={(event) => {
                                 formik.setFieldValue('password_confirmation', event.target.value);
                                 onPasswordChangeHandler(event);
                               }}
                               onBlur={formik.handleBlur}
                               className="hdb-input"
                               maxLength={24}
                             />
                             <div
                               className="hdb-input-group__append"
                               data-show-password="true"
                             >
                               <i className="hdb-icon hdb-icon--eye" onClick={onOpenPassword} />
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="row">
                   <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 hdb-change-password__password-validations">
                     <p>Crie uma senha que:</p>
                     <ul className="hdb-list hdb-list__unstyled">
                       <li
                         id="hdb-form__password-uppercase-validator"
                         className={`hdb-form__password-check ${getValidationClass(validation.hasUpperCase)}`}
                       >
                         <i className="hdb-icon "></i>
                         <span>{translate('LABEL_DETAILS_CONTAINS_CAPITAL_LETTER')}</span>
                       </li>
                       <li
                         id="hdb-form__password-lowercase-validator"
                         className={`hdb-form__password-check ${getValidationClass(validation.hasLowerCase)}`}
                       >
                         <i className="hdb-icon "></i>
                         <span>{translate('LABEL_DETAILS_LOWERCASE_LETTER')}</span>
                       </li>
                       <li
                         id="hdb-form__password-number-validator"
                         className={`hdb-form__password-check ${getValidationClass(validation.hasNumber)}`}
                       >
                         <i className="hdb-icon "></i>
                         <span>{translate('LABEL_DETAILS_AT_LEAST_ONE_NUMBER')}</span>
                       </li>
                       <li
                         id="hdb-form__password-special-validator"
                         className={`hdb-form__password-check ${getValidationClass(validation.hasSpecialCharacter)}`}
                       >
                         <i className="hdb-icon "></i>
                         <span>{translate('LABEL_DETAILS_SPECIAL_CHARACTERS')}</span>
                       </li>
                       <li
                         id="hdb-form__password-size-validator"
                         className={`hdb-form__password-check ${getValidationClass(validation.minLength)}`}
                       >
                         <i className="hdb-icon "></i>
                         <span>{translate('LABEL_DETAILS_EIGHT_CHARACTERS')}</span>
                       </li>
                       <li
                         id="hdb-form__password-equals-validator"
                         className={`hdb-form__password-check ${getValidationClass(formik.values.password === formik.values.password_confirmation && validation.minLength)}`}
                       >
                         <i className="hdb-icon "></i>
                         <span>{translate('LABEL_DETAILS_PASSWORD_CONFIRMATION')}</span>
                       </li>
                     </ul>
                   </div>
                 </div>

                 <div className="row">
                   <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                     <div className="hdb-change-password__actions">
                       <button type="button" className="hdb-button hdb-button--inverted" onClick={() => setShowModalCancel(true)}>
                         {translate('BUTTON_CANCEL')}
                       </button>
                       <button type='submit' className="hdb-button hdb-button--primary" disabled={!(formik.isValid && formik.dirty)}>
                         {translate('BUTTON_SEND')}
                       </button>
                     </div>
                   </div>
                 </div>
               </form>
             </div>
           </div>
         </div> : null
         }
       </div>
     </div>
    </div>
  );
};


export default wrapper(ChangePasswordUserAccount, {
  namespace: 'CHANGE_PASSWORD_USER_ACCOUNT',
  mapState: state => ({
    isAuthenticated: state.auth.isAuthenticated
  })
});

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';

import {AUTH_ROUTES} from 'modules/Auth/constants/routes';
import {authUseCases} from 'services/providers/auth';

import FormLoader from '../../../../components/Form/fragments/FormLoader';
import { PUBLIC_ROUTES } from '../../../App/constants/routes';
import { useNavigate } from 'react-router-dom';

const Logout = ({isAuthenticated}) => {
  const navigate = useNavigate();
  
  const [isLoading] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  
  let redirectUri = urlParams.get('redirectUri');
  if (!/^https?:\/\//.test(redirectUri)){
    redirectUri = 'https://' + redirectUri;
  }
  
  let isRedirectUriValid = false;
  
  if (redirectUri && window.env.REDIRECT_WHITELIST) {
    const redirectArray = window.env.REDIRECT_WHITELIST.split(',');
    
    if (redirectArray.includes(redirectUri)) {
      isRedirectUriValid = true;
    }
  }

  const signOut = async () => {
    if (redirectUri && isRedirectUriValid) {
      await authUseCases.onPremiseSignOut(redirectUri);
    } else {
      await authUseCases.signOut();
    }
  };
  
  const redirect = () => {
    if (redirectUri && isRedirectUriValid) {
      window.location.href = redirectUri;
    } else {
      navigate(PUBLIC_ROUTES.HOME_PAGE)
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      signOut();
    } else {
      setTimeout(() => {
        redirect();
      }, 2000);
    }
  }, [isAuthenticated]);

  return (
    <>
      {isLoading ? <FormLoader /> : null}
    </>
  );
};

export default wrapper(Logout, {
  namespace: 'LOGOUT',
  route: {
    path: AUTH_ROUTES.LOGOUT
  },
  mapState: state => ({
    isAuthenticated: state.auth.isAuthenticated
  })
});

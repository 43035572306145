/* eslint-disable react/prop-types */
import React from 'react';

import wrapper from 'components/Wrapper';

import './style.scss';
import PropTypes from 'prop-types';
import { getAssetUrl } from '../../../helpers/strapiUtils';

const TowardsTheFuture = ({ title, description, image }) => {

  return (
    <div className='hdb-towards-the-future'>
      { image
      ?
        <img src={getAssetUrl(image)} />
      :
        null
      }
      <div className='hdb-towards-the-future__card'>
        <div className='hdb-towards-the-future__text'>
          <strong className='hdb-towards-the-future__title'>
            {title}
          </strong>
          <p className='hdb-towards-the-future__description' dangerouslySetInnerHTML={{__html: description}}></p>
        </div>
      </div>
    </div>
  );
};

TowardsTheFuture.defaultProps = {
  title: '',
  description: '',
  image: null
};

TowardsTheFuture.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object
};

export default wrapper(TowardsTheFuture, {
  namespace: 'TOWARDS_THE_FUTURE'
});

/* eslint-disable react/prop-types */
import React from 'react';

import wrapper from 'components/Wrapper';

import './style.scss';
import PropTypes from 'prop-types';
import { getAssetUrl } from '../../../../helpers/strapiUtils';

const FeatureContent = ({ data }) => {

  return (
    <div className='hdb-feature-content'>
      <img className='hdb-feature-content__top-image' src={getAssetUrl(data?.imagemDestaque)} />
      <div className='hdb-feature-content__card'>
        <div className='hdb-feature-content__text'>
          <div className='hdb-feature-content__logo'>
            <img src={getAssetUrl(data?.logo)} />
          </div>
          <strong className='hdb-feature-content__title'>
            {data?.title}
          </strong>
        </div>
      </div>
    </div>
  );
};

FeatureContent.defaultProps = {
  data: {
  }
};

FeatureContent.propTypes = {
  data: PropTypes.object
};

export default wrapper(FeatureContent, {
  namespace: 'FEATURE_CONTENT'
});

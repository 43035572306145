import {http} from 'config/http/http';
import store from 'config/store/store';

import {userService} from 'services/providers/user';

import AuthService from './service';
import AuthUseCases from './useCases';

export const authService = new AuthService({http});

export const authUseCases = new AuthUseCases({
  store,
  authService,
  userService
});

import { INITIAL_PAGE_ROUTES } from '../../InitialPage/constants/routes';
import { HOME_PAGE_ROUTES} from '../../HomePage/constants/routes';
import { AUTH_ROUTES } from '../../Auth/constants/routes';
import { ABOUT_THE_PROGRAM_ROUTES } from "../../AboutTheProgram/constants/routes";
import { ABOUT_THE_HUB_ROUTES } from '../../AboutTheHub/constants/routes';
import { ABOUT_TRAINING_ROUTES } from '../../AboutTraining/constants/routes';
import { NEWS_ROUTES } from '../../News/constants/routes';
import { PRIVACY_NOTICE_ROUTES } from '../../PrivacyNotice/constants/routes';
import { USER_ROUTES } from '../../User/constants/routes';

export const APP_ROUTES = {
  BASE_ROUTE: '/',
  NOT_ALLOWED: '/not-allowed'
};

export const ADMIN_ROUTES = {
  ...INITIAL_PAGE_ROUTES,
};

export const PRIVATE_ROUTES = {
  ...INITIAL_PAGE_ROUTES,
  ...USER_ROUTES
};

export const PUBLIC_ROUTES = {
  ...HOME_PAGE_ROUTES,
  ...AUTH_ROUTES,
  ...ABOUT_THE_PROGRAM_ROUTES,
  ...ABOUT_THE_HUB_ROUTES,
  ...ABOUT_TRAINING_ROUTES,
  ...NEWS_ROUTES,
  ...PRIVACY_NOTICE_ROUTES
};

import {PUBLIC_CONTENT_API} from './api';

export default class PublicContentService {
  
  /**
   * @param {object} props
   // * @param {import('config/http/axios').Axios} props.http
   */
  constructor({ http }) {
    this.http = http;
  }

  async header() {
    return fetch(PUBLIC_CONTENT_API.HEADER
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }

  async footer() {
    return fetch(PUBLIC_CONTENT_API.FOOTER
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }

  async home() {
    return fetch(PUBLIC_CONTENT_API.HOME
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }

  async faqs() {
    return fetch(PUBLIC_CONTENT_API.FAQS
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }

  async featureNews() {
    return fetch(PUBLIC_CONTENT_API.FEATURE_NEWS
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }

  async news() {
    return fetch(PUBLIC_CONTENT_API.NEWS
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }

  async aboutTheProgram() {
    return fetch(PUBLIC_CONTENT_API.ABOUT_THE_PROGRAM
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }

  async aboutTraining() {
    return fetch(PUBLIC_CONTENT_API.ABOUT_TRAINING
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }

  async aboutTheHub() {
    return fetch(PUBLIC_CONTENT_API.ABOUT_THE_HUB
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }

  async privacyNotice() {
    return fetch(PUBLIC_CONTENT_API.PRIVACY_NOTICE
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }


  async zipCode(zipcode) {
    return fetch(`https://viacep.com.br/ws/${zipcode}/json`
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }
}

import React from 'react';

import wrapper from 'components/Wrapper';

import './style.scss';
import PropTypes from 'prop-types';
import { getAssetUrl } from '../../../../helpers/strapiUtils';

const HubContent = ({ title, data }) => {

  return (
    <div className='hdb-hub-content'>
      <div className='container'>
        <strong className='hdb-hub-content__title'>{title}</strong>
        
        <div className='hdb-hub-content__list'>
          <div className='row'>
            {data.map(item => (
              <div key={`hc-${item.id}`} className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                <div className='hdb-hub-content__item'>
                  <img src={getAssetUrl(item.icone)} alt={item.titulo} />
                  <strong>{item.titulo}</strong>
                  <p dangerouslySetInnerHTML={{__html: item.descricao}}></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

HubContent.defaultProps = {
  title: '',
  data: []
};

HubContent.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array
};

export default wrapper(HubContent, {
});

/* eslint-disable no-template-curly-in-string */
const ptBR = {
  COMMONS: {
    BACK: 'Voltar',
    CANCEL: 'Cancelar',
    SAVE: 'Salvar',
    UPDATE: 'Atualizar',
    REGISTER: 'Inserir',
  },
  ROLES: {
    ROLE_ADMIN: 'Administrador',
  },
  VALIDATION: {
    REQUIRED_FIELD: 'Campo obrigatório',
    STRING_LENGTH: 'Deve ter {{length}} caracteres',
    AT_LEAST_VALUE: 'Dever ser ao menos {{min}}',
    INVALID_EMAIL: 'E-mail inválido',
    INVALID_URL: 'Endereço web inválido',
    MAX_LENGTH: 'Deve ter no máximo {{max}} caracteres',
    INVALID_CNPJ: 'CNPJ inválido',
    INVALID_CPF: 'CPF inválido',
  },
  ERROR: {
    DEFAULT_ERROR:
      'Não foi possível realizar a operação. Caso o problema persista contate o administrador do sistema.',
    TOKEN_INVALID: 'Token inválido ou expirado',
    TOKEN_EXPIRED: 'O token expirou',
    USER_ALREADY_EXISTS_EMAIL: 'E-mail já cadastrado no sistema.',
    USER_ALREADY_EXISTS_CPF: 'O CPF informado possui cadastro no sistema.',
    USER_NOT_LOGGED: 'Usuário não autenticado',
    CAPTCHA_INVALID: 'Erro ao validar ReCAPTCHA',
    TOKEN_ALREADY_CONSUMED: 'Token já utilizado',
    NotAuthorizedException: 'Usuário ou senha inválidos',
    USER_NOT_FOUND: 'Usuário não autorizado a acessar esta aplicação',
    400: 'Não foi possível concluir a requisição',
    401: 'Usuário não autorizado',
    500: 'Ocorreu um erro inesperado',
    'Network Error': 'Ocorreu um erro de rede',
  },
  NOTIFICATIONS: {
    CREATED_SUCCESS: 'Registro incluído com sucesso.',
    UPDATED_SUCCESS: 'Registro alterado com sucesso.',
    DELETED_SUCCESS: 'Registro excluído com sucesso.',
    DEFAULT_ERROR: 'Não foi possível realizar a operação. Caso o problema persista contate o administrador do sistema.',
  },
  CELL_ACTIONS: {
    EDIT: 'Editar',
    DEACTIVATE: 'Desativar',
    RESET_PASSWORD: 'Resetar a senha',
  },
  STATUS: {
    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',
  },
  COPIED_POPUP: {
    COPIED: 'Copiado',
  },
  PROFILE: {
    TITLE: 'Perfil',
    LOGOUT: 'Sair',
  },
  LOGIN: {
    WELCOME_TITLE: 'Seja bem-vindo!',
    WELCOME_MESSAGE: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent condimentum volutpat arcu ut vestibulum. Mauris eget risus et mauris semper bibendum et sed elit. Aenean malesuada semper ante tristique luctus. Fusce quis ligula sit amet ex vulputate elementum vitae eu lectus. Curabitur consequat mi in quam sagittis lacinia. Phasellus mattis feugiat fermentum. Aliquam nec orci lorem.
    Ut vel nisi dignissim, laoreet massa at, elementum tortor. Etiam lobortis sapien risus, in ullamcorper lectus imperdiet egestas. Vestibulum ultricies justo eu tellus placerat placerat. Aliquam id eros massa. Fusce pellentesque dui ac purus semper sodales. Nulla facilisi. Etiam sit amet accumsan nibh. Morbi nisl nibh, volutpat in placerat sed, tincidunt eu magna. In hac habitasse platea dictumst.`,
    LOGIN_TITLE: 'Login',
    USER: 'Usuário',
    USER_PLACEHOLDER: 'Insira seu CPF ou e-mail',
    PASSWORD: 'Senha',
    PASSWORD_PLACEHOLDER: 'Insira sua senha',
    FORGET_PASSWORD: 'Esqueci minha senha',
    SIGNIN: 'Entrar',
    PASSWORD_REQUIRED: 'Senha deve ser preenchida',
    EMAIL_OR_DOCUMENT_REQUIRED: 'Digite um CPF ou um e-mail válidos',
    PRIVACITY_POLICY:
      'Ao utilizar nossos serviços, você concorda com a nossa política de privacidade. <br />Para mais informações, <Link>clique aqui</Link>',
    PRIVACITY_POLICY_PROCEED: 'prosseguir',
  },
  TOKEN: {
    VALIDATION: 'Aguarde enquanto validamos o token...'
  },
  LOGOUT: {
    WAIT: 'Aguarde enquanto encerramos a sua sessão...'
  },
  CREATE_PASSWORD: {
    NEW_PASSWORD: 'Nova senha',
    FORM_FILLING: 'Preencha os dados do usuário:',
    DOCUMENT: 'CPF',
    PASSWORD: 'Senha',
    PASSWORD_PLACEHOLDER: 'Insira sua senha',
    REPEAT_PASSWORD: 'Repetir Senha',
    REPEAT_PASSWORD_PLACEHOLDER: 'Repita sua senha',
    SAVE: 'Salvar',
    PASSWORD_REQUIRED: 'Senha deve ser preenchida',
    CONFIRMATION_INVALID: 'Confirmação não confere com a senha informada',
    TERMS_CONFIRMATION_REQUIRED:
      'Para continuar é preciso aceitar os termos de uso',
    RECAPTCHA_REQUIRED: 'ReCaptcha deve ser informado',
  },
  PASSWORD_UPDATED: {
    PASSWORD_UPDATED: 'Senha atualizada com sucesso!',
    LOGIN: 'Fazer login',
  },
  PASSWORD_VALIDATION: {
    VALIDATION_MUST_HAVE_LOWERCASE: 'Deve conter ao menos uma letra minúscula',
    VALIDATION_MUST_HAVE_UPPERCASE: 'Deve conter ao menos uma letra maiúscula',
    VALIDATION_MUST_HAVE_NUMBER: 'Deve conter ao menos um número',
    VALIDATION_MUST_HAVE_SPECIAL_CHARACTER:
      'Deve conter ao menos um caractere especial',
    VALIDATION_MUST_HAVE_MIN_LENGTH: 'Deve conter ao menos 11 caracteres',
    VALIDATION_MUST_BE_UNPRECEDENTED:
      'Não pode ser igual às últimas 5 senhas utilizadas anteriormente',
  },
  NO_ACCESS: {
    ACCESS_NOT_ALLOWED: 'Você não tem permissão para acessar esta aplicação!',
    ACCESS_HOME_PAGE: 'Ir para a página inicial',
    USER_OPTIONS: 'O que você pode fazer agora:',
  },
  PRIVATE_FOOTER: {
    PRIVACITY_POLICY:
      'Texto destinado a exibição das informações relacionadas à licença de uso.',
  },
  USERS: {
    TITLE: 'Usuários',
    DIALOG_PASSWORD_TITLE: 'Resetar senha',
    DIALOG_PASSWORD_MESSAGE:
      'Tem certeza que deseja limpar as credenciais de acesso deste usuário?',
    TABLE_HEADER_NAME: 'Nome do usuário',
    TABLE_HEADER_CPF: 'CPF',
    TABLE_HEADER_ROLE: 'Perfil',
    TABLE_HEADER_STATUS: 'Status',
    TABLE_HEADER_ACTIONS: 'Ações',
    SEARCH_PLACEHOLDER: 'Pesquise pelo Nome do Usuário ou CPF',
    CREATE_USER: 'Cadastrar Usuário',
  },
  WELCOME: {
    TITLE: 'Bem-vindo ao Hackers do Bem',
    SUBTITLE: 'O que você deseja acessar?',
    BUTTON_VISUALIZER_PROFILE: 'Visualizar Perfil',
    CARD_VISUALIZER_PROFILE_TITLE: 'Minha Conta',
    CARD_VISUALIZER_PROFILE_DESCRIPTION: 'Acesse seus dados de cadastro',
    BUTTON_COURSE_PROFILE: 'Área do Aluno',
    CARD_COURSE_PROFILE_TITLE: 'Meus Cursos',
    CARD_COURSE_PROFILE_DESCRIPTION: 'Acesse sua área de estudos'
  },
  
  VIEW_USER_ACCOUNT: {
    TITLE: "Minha Conta",
    BUTTON_EDIT: "Editar",
    LABEL_CPF: "CPF",
    LABEL_FULL_NAME: "Nome completo",
    LABEL_HAS_SOCIAL_NAME: "Possui Nome Social?",
    LABEL_YES: "Sim",
    LABEL_NO: "Não",
    LABEL_SOCIAL_NAME_PROVIDED: "Se sim, nos informe aqui seu Nome Social",
    LABEL_GENDER: "Gênero",
    LABEL_SELECT_HERE: "Selecione aqui",
    LABEL_INSTITUTION: "Instituição de ensino atual",
    LABEL_BIRTH_DATE: "Data de Nascimento",
    LABEL_ZIP_CODE: "CEP",
    LABEL_DISTRICT: "Bairro",
    LABEL_CITY: "Município",
    LABEL_STATE: "Estado",
    LABEL_CONTACT_DDD_CELL: "Contato (DDD + Celular)",
    LABEL_EDUCATION_LEVEL: "Grau de escolaridade",
    LABEL_HAS_COMPUTER: "Possui computador?",
    LABEL_INTERNET_ACCESS_TYPE: "Qual tipo de acesso a internet você possui?",
    LABEL_ACCESSIBILITY_RESOURCE_REQUIRED: "Necessita de algum recurso de acessibilidade?"
  },

  CHANGE_PASSWORD_USER_ACCOUNT: {
    TITLE: "Criar Nova Senha",
    LABEL_EMAIL: "Qual tipo de acesso a internet você possui?",
    LABEL_PASSWORD: "Crie sua senha",
    LABEL_REPEAT_PASSWORD: "Repita sua senha",
    LABEL_DETAILS_PASSWORD: "Crie uma senha que:",
    LABEL_DETAILS_CONTAINS_CAPITAL_LETTER: "Contenha uma letra maiúscula",
    LABEL_DETAILS_LOWERCASE_LETTER: "Contenha uma letra minúscula",
    LABEL_DETAILS_AT_LEAST_ONE_NUMBER: "Contenha ao menos 1 número",
    LABEL_DETAILS_SPECIAL_CHARACTERS: "Contenha caractere especial &%$@*!",
    LABEL_DETAILS_EIGHT_CHARACTERS: "Contenha ao menos 8 caracteres e no máximo 24 caracteres",
    LABEL_DETAILS_PASSWORD_CONFIRMATION: "Senha e confirmação da senha iguais",
    BUTTON_CANCEL: "Cancelar",
    BUTTON_SEND: "Concluir",
    MODAL_CONFIRM_TITLE:"Informação Importante",
    MODAL_CONFIRM_BODY:'Você tem certeza que deseja salvar todas as alterações feitas?',
    MODAL_CONFIRM_BUTTON_CONFIRM: 'Sim, Salvar',
    MODAL_CONFIRM_BUTTON_CANCEL: 'Não, fechar',
    MODAL_CANCEL_TITLE:"Informação Importante",
    MODAL_CANCEL_SUBTITLE:"Atenção",
    MODAL_CANCEL_BODY:  'Ao optar por cancelar, todos os dados editados não serão salvos. Você tem certeza que deseja cancelar a edição de dados?',
    MODAL_CANCEL_BUTTON_CONFIRM: 'Sim, cancelar',
    MODAL_CANCEL_BUTTON_CANCEL: 'Não, fechar',
    ALERT_TITLE:'Senha alterada!',
    ALERT_BODY:'Senha alterada com <strong class="success">Sucesso.</strong></br>Um e-mail foi enviado confirmando sua alteração de senha',
    ALERT_CONFIRM: 'Ok, fechar',
    PASSWORD_ERROR: 'Esta senha já foi utilizada, crie uma nova senha.'
  },

  USER_ACCOUNT: {
    BREADCRUMB_TITLE: "Minha Conta",
    TITLE: "Editar Dados",
    LABEL_CPF: "CPF",
    LABEL_FULL_NAME: "Nome completo",
    LABEL_HAS_SOCIAL_NAME: "Possui Nome Social?",
    LABEL_YES: "Sim",
    LABEL_NO: "Não",
    LABEL_SOCIAL_NAME_PROVIDED: "Se sim, nos informe aqui seu Nome Social",
    LABEL_GENDER: "Gênero",
    LABEL_SELECT_HERE: "Selecione aqui",
    LABEL_BIRTH_DATE: "Data de Nascimento",
    LABEL_ZIP_CODE: "CEP",
    ZIP_CODE_INVALID: "CEP inválido",
    LABEL_DISTRICT: "Bairro",
    LABEL_CITY: "Município",
    LABEL_STATE: "Estado",
    LABEL_OPTIONAL: "Opcional",
    LABEL_TYPE: "Digite aqui...",
    LABEL_INSTITUTION: "Instituição de ensino atual",
    LABEL_CONTACT_DDD_CELL: "Contato (DDD + Celular)",
    LABEL_EDUCATION_LEVEL: "Grau de escolaridade",
    LABEL_CURRENT_EDUCATIONAL_INSTITUTION: "Instituto de Ensino Qualquer",
    LABEL_HAS_COMPUTER: "Possui computador?",
    LABEL_INTERNET_ACCESS_TYPE: "Qual tipo de acesso a internet você possui?",
    LABEL_ACCESSIBILITY_RESOURCE_REQUIRED: "Necessita de algum recurso de acessibilidade?",
    BUTTON_CANCEL: "Cancelar",
    BUTTON_SEND: "Enviar",
    MODAL_CONFIRM_TITLE:"Informação Importante",
    MODAL_CONFIRM_BODY:'Você tem certeza que deseja salvar todas as alterações feitas?',
    MODAL_CONFIRM_BUTTON_CONFIRM: 'Sim, Salvar',
    MODAL_CONFIRM_BUTTON_CANCEL: 'Não, fechar',
    MODAL_CANCEL_TITLE:"Informação Importante",
    MODAL_CANCEL_SUBTITLE:"Atenção",
    MODAL_CANCEL_BODY:  'Ao optar por cancelar, todos os dados editados não serão salvos. Você tem certeza que deseja cancelar a edição de dados?',
    MODAL_CANCEL_BUTTON_CONFIRM: 'Sim, cancelar',
    MODAL_CANCEL_BUTTON_CANCEL: 'Não, fechar',
    ALERT_TITLE:'Edição Concluída!',
    ALERT_BODY:'Seus dados cadastrais foram alterados com <strong class="success">Sucesso<strong>.',
    ALERT_CONFIRM: 'Ok, fechar',
    UPDATE_ERROR: 'Erro ao atualizar os dados',
    TOOLTIP_CPF: 'Documento brasileiro de identificação individual.',
    TOOLTIP_FULL_NAME: 'Nome de registro civil que consta nos documentos oficiais.',
    TOOLTIP_HAS_SOCIAL_NAME: 'Nome utilizado e como prefere ser chamada pela pessoa que não se reconhece em sua identidade civil, possuindo a mesma proteção ao nome de registro, assegurada pelo Decreto nº 8.727/2016.',
    TOOLTIP_GENDER: 'Documento brasileiro de identificação individual.',
    TOOLTIP__EDUCATION_LEVEL: 'Nível de instrução adquirido por meio de educação formal.',
    TOOLTIP_INSTITUTION: 'Nível de instrução adquirido por meio de educação formal.',
    TOOLTIP_ACCESSIBILITY_RESOURCE_REQUIRED: 'Indicação de ferramentas e adaptações que visam tornar informações, plataformas e serviços acessíveis a pessoas com deficiência e/ou neurodiversas.'
  },
  
  REQUEST_PASSWORD_RESET: {
    WELCOME_TITLE: 'Seja bem-vindo!',
    WELCOME_MESSAGE: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent condimentum volutpat arcu ut vestibulum. Mauris eget risus et mauris semper bibendum et sed elit. Aenean malesuada semper ante tristique luctus. Fusce quis ligula sit amet ex vulputate elementum vitae eu lectus. Curabitur consequat mi in quam sagittis lacinia. Phasellus mattis feugiat fermentum. Aliquam nec orci lorem.
    Ut vel nisi dignissim, laoreet massa at, elementum tortor. Etiam lobortis sapien risus, in ullamcorper lectus imperdiet egestas. Vestibulum ultricies justo eu tellus placerat placerat. Aliquam id eros massa. Fusce pellentesque dui ac purus semper sodales. Nulla facilisi. Etiam sit amet accumsan nibh. Morbi nisl nibh, volutpat in placerat sed, tincidunt eu magna. In hac habitasse platea dictumst.`,
    TITLE: 'Recuperação de Senha',
    USER: 'Usuário',
    USER_PLACEHOLDER: 'Insira seu CPF ou e-mail',
    CONFIRM: 'Confirmar',
    EMAIL_OR_DOCUMENT_REQUIRED: 'Digite um CPF ou um e-mail válidos',
    EMAIL_SENT: 'E-mail enviado!',
    EMAIL_SENT_CONFIRMATION:
      'Foi enviado um e-mail para sua conta pessoal. Siga as instruções para recuperar sua senha.',
    GOT_IT: 'Entendi',
  },
  FORM_LOADING: {
    LOADING: 'Carregando...',
    INITIALIZING: 'Inicializando...'
  },
  MFA_SETUP: {
    CONFIGURE_MFA: 'Configurar Código de Verificação',
    CONFIRM_CODE: 'Enviar Código',
    DOWNLOAD_AUTHENTICATOR:
      'Baixe o aplicativo de autenticação em seu celular para escanear o código QR:',
    COPY_SECRET_1: 'Ou',
    COPY_SECRET_2: 'clique aqui',
    COPY_SECRET_3:
      'para copiar o código de autenticação e utilizar em um aplicativo em seu computador.',
    DOWNLOAD_ON_GOOGLE_PLAY: 'Baixar Google Authenticator para Android',
    DOWNLOAD_ON_APP_STORE: 'Baixar Google Authenticator para iOS',
    APP_STORE: 'App Store',
    GOOGLE_PLAY: 'Google Play',
    TOKEN_EXCEPTION:
      'Código inválido. Tente um novo código ou cadastre o QRCode novamente.',
    INTERNAL_ERROR_EXCEPTION: 'Falha na conexão, tente novamente.',
    DEFAULT_ERROR: 'Não foi possível concluir a requisição.',
  },
  MFA_TOKEN: {
    MFA: 'Código de Verificação',
    DESCRIPTION:
      'Busque o código de 6 digitos que será apresentado no aplicativo de autenticação na qual você vinculou o QR Code na etapara de cadastro do sistema',
    CONFIRM_CODE: 'Enviar Código',
    TOKEN_EXCEPTION: 'Código inválido. Tente um novo código',
    INTERNAL_ERROR_EXCEPTION: 'Falha na conexão, tente novamente.',
    DEFAULT_ERROR: 'Não foi possível concluir a requisição.',
  },
  SELECT_SECURITY_QUESTIONS: {
    SAVE: 'Salvar',
    ANSWER: 'Resposta',
    ANSWER_PLACEHOLDER: 'Resposta:',
    SELECT_QUESTIONS_TITLE:
      'Escolha e responda as duas perguntas abaixo para prosseguir:',
    SELECT_QUESTIONS: 'Selecione uma pergunta...',
    SECURITY_QUESTIONS: 'Perguntas e respostas',
    QUESTION_REQUIRED: 'Pergunta deve ser selecionada',
    ANSWER_REQUIRED: 'Resposta deve ser informada',
    FIRST_QUESTION: 'Pergunta 1',
    SECOND_QUESTION: 'Pergunta 2',
    HINT: 'Dica: ',
    HINT_TEXT:
      'Escolha perguntas que possa lembrar facilmente a resposta, pois esse processo será crucial para recuperação de senha no futuro.',
  },
  RESET_PASSWORD: {
    ANSWER: 'Resposta:',
    ANSWER_REQUIRED: 'Resposta da pergunta deve ser preenchida',
    QUESTION_REQUIRED: 'Houve um erro ao buscar a pergunta de segurança',
    BACK_TO_HOME: 'Voltar para página inicial',
    INVALID_TOKEN: 'Seu token é inválido ou expirou.',
    LINK_EMAIL: 'Informe seu e-mail ',
    TEXT_TOKEN: 'para gerar um novo token <br />ou volte para a ',
    LINK_INICIAL: 'página inicial',
  },
  LIST: {
    NONE_RESULTS: 'Nenhum registro encontrado',
  },
  PUBLIC_FOOTER: {
    TEXT: 'Texto destinado à exibição das informações relacionadas ao',
    PRIVACY_NOTICE: 'Aviso de Privacidade'
  }
};

export default ptBR;

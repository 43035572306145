import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';
import './style.scss';
import { authService } from '../../../../services/providers/auth';
import { userUseCases } from '../../../../services/providers/user';
import { keycloakUseCases } from '../../../../services/providers/keycloak';
import { NavLink } from 'react-router-dom';
import Breadcrumb from '../../../../components/Strapi/Breadcrumb';
import { PRIVATE_ROUTES } from '../../../App/constants/routes';
import { useLangContext } from '../../../../components/Globalization';
import FormLoader from '../../../../components/Form/fragments/FormLoader';

const ViewUserAccount = ({ isAuthenticated }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [genders, setGenders] = useState([]);
  const [scholarities, setScholarities] = useState([]);
  const [internetConnections, setInternetConnections] = useState([]);
  const translate = useLangContext();
  const [breadcrumb] = useState([{
    name: translate('TITLE'),
    link: PRIVATE_ROUTES.WELCOME_PAGE
  }]);

  const init = () => {
    getData();
  };

  useEffect(() => {
    if (isAuthenticated) {
      init();
    }
  }, [isAuthenticated]);

  const getData = async () => {
    setIsLoading(true);
    try {
      const userId = await authService.getSubFromTokenAsUserId();
      const response = await userUseCases.findUserAccount(userId);
      setGenders((await keycloakUseCases.genders()));
      setScholarities((await keycloakUseCases.scholarities()));
      setInternetConnections((await keycloakUseCases.internetConnection()));
      setIsLoading(false);
      setData(response);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  function findDescriptionFromScholarities(key) {
    return scholarities.find(obj => obj.id === key)?.value;
  }
  
  function findDescriptionFromGenders(key) {
    return genders.find(obj => obj.id === key)?.value;
  }

  function findDescriptionFromInternetConnections(key) {
    return internetConnections.find(obj => obj.id === key)?.value;
  }

  const formatDate = (inputDate) => {
    if(inputDate){
      const dateParts = inputDate?.split('-');
      const day = dateParts[2];
      const month = dateParts[1];
      const year = dateParts[0];
      return `${day}/${month}/${year}`;
    }
    return '';
  };
  
  return (
    <div className='hdb-view-account'>
      {isLoading ? <FormLoader /> : null}
      
      <div className='hdb-blue-gradient-container'>
        <img src='/assets/img/hdb_user-form_bg.png' className='hdb-update-account__circles' height='215' />
        
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <Breadcrumb routes={breadcrumb} showBack={true} backUri={PRIVATE_ROUTES.WELCOME_PAGE} isAuthenticated={isAuthenticated} />
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='hdb-view-account__title'>
                <h1>{translate('TITLE')}</h1>
                <NavLink to={PRIVATE_ROUTES.EDIT_PROFILE} title='User account' className='hdb-button hdb-button--primary'>
                  {translate('BUTTON_EDIT')}
                </NavLink>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='hdb-view-account__data'>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      CPF
                    </span>
                    <strong>
                      {data.documentNumber}
                    </strong>
                  </div>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      {translate('LABEL_FULL_NAME')}
                    </span>
                    <strong>
                      {data.fullName}
                    </strong>
                  </div>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      {translate('LABEL_HAS_SOCIAL_NAME')}
                    </span>
                    <strong>
                      {data.hasSocialName === 'true' ? translate('LABEL_YES') : translate('LABEL_NO')}
                    </strong>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      {translate('LABEL_SOCIAL_NAME_PROVIDED')}
                    </span>
                    <strong>
                      {data.socialName ? data.socialName : '-'}
                    </strong>
                  </div>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      {translate('LABEL_GENDER')}
                    </span>
                    <strong dangerouslySetInnerHTML={{__html: findDescriptionFromGenders(data.gender)}}/>
                  </div>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      {translate('LABEL_BIRTH_DATE')}
                    </span>
                    <strong>
                      {formatDate(data.birthdate)}
                    </strong>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      {translate('LABEL_ZIP_CODE')}
                    </span>
                    <strong>
                      {data.zipCode}
                    </strong>
                  </div>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      {translate('LABEL_DISTRICT')}
                    </span>
                    <strong>
                      {data.neighborhood}
                    </strong>
                  </div>
                  <div className='col-12 col-sm-12 col-md-2'>
                    <span>
                      {translate('LABEL_CITY')}
                    </span>
                    <strong>
                      {data.city}
                    </strong>
                  </div>
                  <div className='col-12 col-sm-12 col-md-2'>
                    <span>
                      {translate('LABEL_STATE')}
                    </span>
                    <strong>
                      {data.state}
                    </strong>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      {translate('LABEL_CONTACT_DDD_CELL')}
                    </span>
                    <strong>
                      {data.contactNumber}
                    </strong>
                  </div>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      {translate('LABEL_EDUCATION_LEVEL')}
                    </span>
                    <strong dangerouslySetInnerHTML={{__html: findDescriptionFromScholarities(data.educationalLevel)}}/>
                  </div>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      {translate('LABEL_INSTITUTION')}
                    </span>
                    <strong>
                      {data.institution ? data.institution : '-'}
                    </strong>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      {translate('LABEL_HAS_COMPUTER')}
                    </span>
                    <strong>
                      {data.hasPC === 'true' ? translate('LABEL_YES') : translate('LABEL_NO')}
                    </strong>
                  </div>
                  <div className='col-12 col-sm-12 col-md-4'>
                    <span>
                      {translate('LABEL_INTERNET_ACCESS_TYPE')}
                    </span>
                    <strong dangerouslySetInnerHTML={{ __html: findDescriptionFromInternetConnections(data.internetType)}} />
                  </div>
                  <div className='col-12 col-sm-12 col-md-4'>
                <span>
                  {translate('LABEL_ACCESSIBILITY_RESOURCE_REQUIRED')}
                </span>
                    <strong>
                      {data.accessibility}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default wrapper(ViewUserAccount, {
  namespace: 'VIEW_USER_ACCOUNT',
  mapState: state => ({
    isAuthenticated: state.auth.isAuthenticated
  })
});
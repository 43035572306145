import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';
import './style.scss';
import { useFormik } from 'formik';
import { userUseCases } from '../../../../services/providers/user';
import { authService } from '../../../../services/providers/auth';
import ConfirmDialog from '../../../../components/Dialog/ConfirmDialog';
import Alert from '../../../../components/Dialog/Alert';
import UserAccountFormSchema from './schemas/user-account-form-schema';
import { keycloakUseCases } from '../../../../services/providers/keycloak';
import { PRIVATE_ROUTES } from '../../../App/constants/routes';
import Breadcrumb from '../../../../components/Strapi/Breadcrumb';
import { useLangContext } from '../../../../components/Globalization';
import { useNavigate } from 'react-router-dom';
import FormLoader from '../../../../components/Form/fragments/FormLoader';
import { ToastError } from '../../../../components/Toast';
import { zipCodeUseCases } from '../../../../services/providers/zipCode';
import { Tooltip } from '../../../../components/Tooltip';


const UpdateUserAccount = ({isAuthenticated}) => {

  const navigate = useNavigate();
  const translate = useLangContext();
  
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalSuccessEdition, setShowModalSuccessEdition] = useState(false);
  const [genders, setGenders] = useState([]);
  const [scholarities, setScholarities] = useState([]);
  const [internetConnections, setInternetConnections] = useState([]);
  const [maxDate, setMaxDate] = useState('');
  const [address,setAddress] = useState({});
  const [error, setError] = useState(null);
  const [zipCodeValid, setZipCodeValid] = useState(false);
  const [hasUserData, setHasUserData] = useState(false);
  const [breadcrumb] = useState([{ 
    name: translate('BREADCRUMB_TITLE'), 
    link: PRIVATE_ROUTES.MY_PROFILE
  }, {
    name: translate('TITLE')
  }]);
  
  const formik = useFormik({
    initialValues: {
      hasPC: 'false',
      hasSocialName: 'true',
      fullName: '',
      accessibility : '',
      birthdate: '',
      city: '',
      contactNumber: '',
      documentNumber: '',
      educationalLevel: '',
      email: '',
      firstName: '',
      gender : '',
      institution: '',
      internetType: '',
      lastName: '',
      neighborhood: '',
      recoveryEmail: '',
      state: '',
      welcome: '',
      zipCode : ''
    },
    validationSchema: UserAccountFormSchema.schema,
    onSubmit: () => {
      openModal()
    }
  });

  const init = () => {
    getData();
    setBirthdateMaxValue();
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const userId = await authService.getSubFromTokenAsUserId();
      const response = await userUseCases.findUserAccount(userId);
      setGenders(await keycloakUseCases.genders());
      setInternetConnections((await keycloakUseCases.internetConnection()));
      setScholarities(await keycloakUseCases.scholarities());
      await formik.setValues(response);
      setHasUserData(true);
      setIsLoading(false);
    } catch (e) {
      setHasUserData(false);
      setIsLoading(false);
      console.error(e);
    }
  };

  const isCEPValid = (cep) => {
    const cepRegex = /^[0-9]{5}-[0-9]{3}$/;
    return cepRegex.test(cep);
  };

  const setBirthdateMaxValue = () => {
    const nowArr = new Date().toLocaleDateString().replaceAll('/', '-').split('-');
    const now = `${nowArr[2]}-${nowArr[1]}-${nowArr[0]}`;
    setMaxDate(now);
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, '');
    
    const formattedValue = numericValue.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3-$4');

    formik.handleChange({
      target: {
        name: 'contactNumber',
        value: formattedValue,
      },
    });
  };

  const handleZipCodeChange = (event) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, '');

    const formattedValue = numericValue.replace(/^(\d{5})(\d{0,3})$/, '$1-$2');

    formik.handleChange({
      target: {
        name: 'zipCode',
        value: formattedValue,
      },
    });
  };
  
  const onConfirmModalSave = async () => {
    await updateFirstAndLastName();
    setIsSubmitting(true);
  };
  
  const updateUser = () => {
    setIsLoading(true);
    
    try {
      userUseCases.updateUserAccount(formik.values).then(() => {
        setShowModalConfirm(false);
        setShowModalSuccessEdition(true);
      }).catch((error) => {
        setShowModalConfirm(false);
        const msg = error?.response?.data?.message ? JSON.parse(error.response.data.message).map( o => o.message).toString() : translate('UPDATE_ERROR')
        setError(msg);
        console.error(msg, error);
      }).finally(() => {
        setIsLoading(false);
      });
      setShowModalConfirm(false);
    }catch (e) {
      setShowModalConfirm(false);
      setIsLoading(false);
      console.error(e);
    }
  };
  
  const updateFirstAndLastName = async () => {
    const firstName = formik.values.fullName.split(' ')[0];
    const lastName = formik.values.fullName.replace(firstName + ' ', '');
    await formik.setFieldValue('firstName', firstName);
    await formik.setFieldValue('lastName', lastName);
    await formik.setFieldTouched('firstName', true);
    await formik.setFieldTouched('lastName', true);
  };
  
  const onConfirmModelCancel = () => {
    setShowModalCancel(false);
    navigate(PRIVATE_ROUTES.MY_PROFILE);
  };
  
  const openModal = () => {
    if(formik.isValid){
      setShowModalConfirm(true)
    }
  };
  
  const getZipCode = (showLoader) => {
    if (showLoader) {
      setIsLoading(true);
    }
    try {
      if (isCEPValid(formik.values.zipCode)) {
        zipCodeUseCases.get(formik.values.zipCode.replaceAll('\D/g', ''))
          .then((response) => response.json())
          .then((data) => {
            setZipCodeValid(!!data.erro)
            setAddress(data)
            formik.setValues({
              ...formik.values,
              neighborhood: formik.values.neighborhood ? formik.values.neighborhood : data.bairro,
              city: formik.values.city ? formik.values.city : data.localidade,
              state: formik.values.state ? formik.values.state : data.uf
            });
          })
          .catch((error) => {
            const msg = 'Erro ao obter informações do CEP';
            setError(msg)
            console.error(msg, error)
          });
      }else{
        formik.setValues({
          ...formik.values,
          neighborhood: '',
          city: '',
          state: '',
        });
      }
      if (showLoader) {
        setIsLoading(false);
      }
    } catch (e) {
      if (showLoader) {
        setIsLoading(false);
      }
      console.error(e);
    }
  };
  
  const getFeedbackError = (form, field) => {
    if (form.errors[field] && form.touched[field]) {
      return <div className='hdb-input__feedback hdb-input__feedback--error hdb-input__feedback--active'>
        <small>
          <i className='hdb-icon hdb-icon--times-circle'></i>
          <span>{formik.errors[field]}</span>
        </small>
      </div>;
    }

    return null;
  };

  useEffect(() => {
    if (isAuthenticated) {
      init();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    getZipCode(hasUserData);
  }, [formik.values.zipCode, hasUserData]);

  useEffect(() => {
    if (isSubmitting) {
      setIsSubmitting(true);
      updateUser();
    }
  }, [formik.values, isSubmitting]);
  
  return (
   <div className='hdb-update-account'>
     <ToastError show={error} message={error} />
     <ConfirmDialog
       open={showModalConfirm}
       title={translate('MODAL_CONFIRM_TITLE')}
       textBody={translate('MODAL_CONFIRM_BODY')}
       buttonConfirm={{name: translate('MODAL_CONFIRM_BUTTON_CONFIRM'), onConfirm: onConfirmModalSave}}
       buttonCancel={{name: translate('MODAL_CONFIRM_BUTTON_CANCEL'), onCancel: () => setShowModalConfirm(false)}}
     />
     <ConfirmDialog
       open={showModalCancel}
       title={translate('MODAL_CANCEL_TITLE')}
       subtitle={translate('MODAL_CANCEL_SUBTITLE')}
       textBody={translate('MODAL_CANCEL_BODY')}
       buttonConfirm={{name: translate('MODAL_CANCEL_BUTTON_CONFIRM'), onConfirm: onConfirmModelCancel}}
       buttonCancel={{name: translate('MODAL_CANCEL_BUTTON_CANCEL'), onCancel: () => setShowModalCancel(false) }}
       icon='../../../../assets/img/hdb_warning_modal_icon.png'
     />
     <Alert
       open={showModalSuccessEdition}
       title={translate('ALERT_TITLE')}
       textBody={translate('ALERT_BODY')}
      icon='../../../../assets/img/hdb_circle_right.png' 
       button={{name: translate('ALERT_CONFIRM'), onConfirm: PRIVATE_ROUTES.WELCOME_PAGE}}
     />

     {isLoading ? <FormLoader /> : null}
     
     <div className='hdb-blue-gradient-container'>
       <img src='/assets/img/hdb_user-form_bg.png' className='hdb-update-account__circles' height='215' />
       
       <div className='container'>
         <div className='row'>
           <div className='col-12'>
             <Breadcrumb routes={breadcrumb} isAuthenticated={isAuthenticated} />
           </div>
         </div>

         <div className='row'>
           <div className='col-12'>
             <div className='hdb-update-account__title'>
               <h1>{translate('TITLE')}</h1>
             </div>
           </div>
         </div>
         
         <div className='row'>
           <div className='col'>
             <form className='hdb-update-account__form' onSubmit={formik.handleSubmit} >
               <div className='hdb-update-account__content'>
                 <div className='row'>
                   <div className='col-12 col-md-6 col-lg-2'>
                     <div className='hdb-input-group'>
                       <label htmlFor='documentNumber'>{translate('LABEL_CPF')}
                         <Tooltip icon='hdb-icon--info-circle'  information={translate('TOOLTIP_CPF')} />
                       </label>
                       <p className='hdb-update-account__user-document'>{formik.values.documentNumber}</p>
                     </div>
                   </div>
                   <div className='col'>
                     <div className='hdb-input-group'>
                       <label htmlFor='fullName'>{translate('LABEL_FULL_NAME')}
                         <Tooltip icon='hdb-icon--info-circle' information={translate('TOOLTIP_FULL_NAME')}  />
                       </label>
                       <input
                         type='text'
                         value={formik.values.fullName}
                         id='fullName'
                         className='hdb-input'
                         placeholder='Digite aqui...'
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         required=''
                       />

                       { getFeedbackError(formik, 'fullName') }
                     </div>
                   </div>
                   <div className='col-12 col-md-6 col-lg-2'>
                     <div className='hdb-input-group'>
                       <label htmlFor='hasSocialName'>
                         {translate('LABEL_HAS_SOCIAL_NAME')}
                         <Tooltip icon='hdb-icon--info-circle' information={translate('TOOLTIP_HAS_SOCIAL_NAME')}/>
                       </label>
                       <div className='hdb-radio-group'>
                         <div className='hdb-input__radio'>
                           <input
                             id='hasSocialName'
                             name='hasSocialName'
                             data-id='HAS_SOCIAL_NAME'
                             checked={formik.values.hasSocialName === 'true'}
                             type='radio'
                             onChange={formik.handleChange}
                             onBlur={formik.handleBlur}
                             value='true'
                           />
                           <label
                             htmlFor='hasSocialName'
                             className='hdb-input__radio-label'
                           >
                             Sim
                           </label>
                         </div>
                         <div className='hdb-input__radio'>
                           <input
                             id='dontHaveSocialName'
                             name='hasSocialName'
                             data-id='HAS_SOCIAL_NAME'
                             value='false'
                             checked={formik.values.hasSocialName === 'false'}
                             type='radio'
                             onBlur={formik.handleBlur}
                             onChange={(e) => {
                               formik.setFieldValue('hasSocialName', e.target.value);
                               formik.setFieldValue('socialName', '');
                             }
                             }
                           />
                           <label
                             htmlFor='dontHaveSocialName'
                             className='hdb-input__radio-label'
                           >
                             Não
                           </label>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>

                 <div className='row'>
                   <div className='col'>
                     <div className='hdb-input-group'>
                       <label htmlFor='socialName'>
                         {translate('LABEL_SOCIAL_NAME_PROVIDED')}
                       </label>
                       <input
                         type='text'
                         name='socialName'
                         id='socialName'
                         value={formik.values.socialName}
                         data-id='SOCIAL_NAME'
                         className='hdb-input'
                         placeholder='Digite aqui... '
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         readOnly={formik.values.hasSocialName === 'false'}
                       />
                       
                       { getFeedbackError(formik, 'socialName') }
                     </div>
                   </div>
                   <div className='col-12 col-md-6 col-lg-3'>
                     <div className='hdb-input-group'>
                       <label htmlFor='gender'>{translate('LABEL_GENDER')}
                         <Tooltip icon='hdb-icon--info-circle' information={translate('TOOLTIP_GENDER')} />
                       </label>
                       <div className='hdb-select-group'>
                         <select
                           name='gender'
                           id='gender'
                           data-id='GENDER'
                           className='hdb-select'
                           required=''
                           onBlur={formik.handleBlur}
                           value={formik.values.gender}
                           onChange={(e) => formik.setFieldValue('gender', e.target.value)}>
                           <option value='' selected='' disabled=''>
                             {translate('LABEL_SELECT_HERE')}
                           </option>
                           {
                             genders?.map((option) => (
                               <option key={option.id} value={option.id} dangerouslySetInnerHTML={{ __html: option.value }}/>
                             ))
                           }
                         </select>
                       </div>

                       { getFeedbackError(formik, 'gender') }
                     </div>
                   </div>
                   <div className='col-12 col-md-6 col-lg-2'>
                     <div className='hdb-input-group'>
                       <label htmlFor='birthdate'>{translate('LABEL_BIRTH_DATE')}</label>
                       <input
                         type='date'
                         name='birthdate'
                         id='birthdate'
                         data-id='BIRTHDATE'
                         className='hdb-input'
                         required=''
                         onChange={(event) => {
                           formik.setFieldValue('birthdate', event.target.value);
                           setBirthdateMaxValue();
                         }}
                         onBlur={formik.handleBlur}
                         value={formik.values.birthdate}
                         max={maxDate}
                         min='1823-12-14'
                       />

                       { getFeedbackError(formik, 'birthdate') }
                     </div>
                   </div>
                 </div>
                 <div className='row'>
                   <div className='col-12 col-md-6 col-lg-2'>
                     <div className='hdb-input-group'>
                       <label htmlFor='zipCode'>{translate('CEP')}</label>
                       <div className='hdb-input-group__icon-group'>
                         <input
                           type='text'
                           name='zipCode'
                           id='zipCode'
                           data-id='ZIP_CODE'
                           className='hdb-input'
                           placeholder='00000-000'
                           required=''
                           maxLength='9'
                           value={formik.values.zipCode}
                           onChange={handleZipCodeChange}
                           onBlur={formik.handleBlur}
                         />
                         <div className='hdb-input-group__append'>
                           <i className='hdb-icon hdb-icon--search' />
                         </div>
                       </div>
                       { zipCodeValid
                         ?
                         <div
                           className='hdb-input__feedback hdb-input__feedback--error hdb-input__feedback--active'
                         >
                           <small>
                             <i className='hdb-icon hdb-icon--times-circle'></i>
                             <span>{translate('ZIP_CODE_INVALID')}</span>
                           </small>
                         </div>
                         :
                         null
                       }
                       
                       { getFeedbackError(formik, 'zipCode') }
                     </div>
                   </div>
                   <div className='col-12 col-md-6 col-lg-3'>
                     <div className='hdb-input-group'>
                       <label htmlFor='neighborhood'>{translate('LABEL_DISTRICT')}</label>
                       <input
                         type='text'
                         name='neighborhood'
                         id='neighborhood'
                         data-id='NEIGHBORHOOD'
                         value={formik.values.neighborhood}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         className='hdb-input'
                         placeholder='Digite aqui...'
                         readOnly={address.bairro}
                       />

                       { getFeedbackError(formik, 'neighborhood') }
                     </div>
                   </div>
                   <div className='col-12 col-md-6 col-lg-4'>
                     <div className='hdb-input-group'>
                       <label htmlFor='city'>{translate('LABEL_CITY')}</label>
                       <input
                         type='text'
                         name='city'
                         id='city'
                         data-id='CITY'
                         className='hdb-input'
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         value={formik.values.city}
                         placeholder='Digite aqui...'
                         readOnly={address.localidade}
                       />

                       { getFeedbackError(formik, 'city') }
                     </div>
                   </div>
                   <div className='col-12 col-md-6 col-lg-3'>
                     <div className='hdb-input-group'>
                       <label htmlFor='state'>{translate('LABEL_STATE')}</label>
                       <input
                         type='text'
                         name='state'
                         id='state'
                         data-id='STATE'
                         className='hdb-input'
                         placeholder='Digite aqui...'
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         value={formik.values.state}
                         readOnly={address.uf}
                       />

                       { getFeedbackError(formik, 'state') }
                     </div>
                   </div>
                 </div>
                 <div className='row'>
                   <div className='col-12 col-md-6 col-lg-3'>
                     <div className='hdb-input-group'>
                       <label htmlFor='contactNumber'>
                         {translate('LABEL_CONTACT_DDD_CELL')}
                       </label>
                       <input
                         type='text'
                         name='contactNumber'
                         id='contactNumber'
                         data-id='CONTACT_NUMBER'
                         className='hdb-input'
                         placeholder='(00) 0 0000-0000'
                         maxLength='16'
                         onChange={handlePhoneNumberChange}
                         onBlur={formik.handleBlur}
                         value={formik.values.contactNumber}
                         required=''
                       />

                       { getFeedbackError(formik, 'contactNumber') }
                     </div>
                   </div>
                   <div className='col-12 col-md-6 col-lg-4'>
                     <div className='hdb-input-group'>
                       <label htmlFor='educationalLevel'>
                         {translate('LABEL_EDUCATION_LEVEL')}
                         <Tooltip icon='hdb-icon--info-circle' information={translate('TOOLTIP_EDUCATION_LEVEL')}/>
                       </label>
                       <div className='hdb-select-group'>
                         <select
                           name='educationalLevel'
                           id='educationalLevel'
                           data-id='EDUCATION_LEVEL'
                           className='hdb-select'
                           required=''
                           onBlur={formik.handleBlur}
                           value={formik.values.educationalLevel}
                           onChange={(e) => formik.setFieldValue('educationalLevel', e.target.value)}>
                           <option value='' selected='' disabled=''>
                             {translate('LABEL_SELECT_HERE')}
                           </option>
                           {
                             scholarities?.map((option) => (
                               <option key={option.id} value={option.id} dangerouslySetInnerHTML={{ __html: option.value }}/>
                             ))
                           }
                         </select>
                       </div>

                       { getFeedbackError(formik, 'educationalLevel') }
                     </div>
                   </div>
                   <div className='col-12 col-md-6 col-lg-5'>
                     <div className='hdb-input-group'>
                       <label htmlFor='institution'>
                         {translate('LABEL_INSTITUTION')}
                         <Tooltip icon='hdb-icon--info-circle' information={translate('TOOLTIP_INSTITUTION')}/>
                         <small>({translate('LABEL_OPTIONAL')})</small>
                       </label>
                       <input
                         type='text'
                         name='institution'
                         id='institution'
                         data-id='INSTITUTION'
                         className='hdb-input'
                         value={formik.values.institution}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         placeholder='Digite aqui...'
                       />

                       { getFeedbackError(formik, 'institution') }
                     </div>
                   </div>
                 </div>
                 <div className='row'>
                   <div className='col-12 col-md-6 col-lg-2'>
                     <div className='hdb-input-group'>
                       <label htmlFor='hasPc'>{translate('LABEL_HAS_COMPUTER')}</label>
                       <div className='hdb-radio-group'>
                         <div className='hdb-input__radio'>
                           <input
                             id='hasPc'
                             name='hasPc'
                             data-id='HAS_PC'
                             checked={formik.values.hasPC === 'true'}
                             type='radio'
                             onChange={(e) => formik.setFieldValue('hasPC', e.target.value)}
                             onBlur={formik.handleBlur}
                             value='true'
                           />
                           <label
                             htmlFor='hasPc'
                             className='hdb-input__radio-label'>
                             {translate('LABEL_YES')}
                           </label>
                         </div>
                         <div className='hdb-input__radio'>
                           <input
                             id='dontHavePc'
                             name='hasPc'
                             data-id='HAS_PC'
                             checked={formik.values.hasPC === 'false'}
                             type='radio'
                             value='false'
                             onChange={(e) => formik.setFieldValue('hasPC', e.target.value)}
                             onBlur={formik.handleBlur}
                           />
                           <label
                             htmlFor='dontHavePc'
                             className='hdb-input__radio-label'
                           >
                             {translate('LABEL_NO')}
                           </label>
                         </div>
                       </div>
                     </div>
                   </div>
                   <div className='col-12 col-md-6 col-lg-4'>
                     <div className='hdb-input-group'>
                       <label htmlFor='internetType'>
                         {translate('LABEL_INTERNET_ACCESS_TYPE')}
                       </label>
                       <div className='hdb-select-group'>
                         <select
                           name='internetType'
                           id='internetType'
                           data-id='INTERNET_TYPE'
                           className='hdb-select'
                           required=''
                           value={formik.values.internetType}
                           onBlur={formik.handleBlur}
                           onChange={(e) => formik.setFieldValue('internetType', e.target.value)}>
                           <option value='' selected='' disabled=''>
                             {translate('LABEL_SELECT_HERE')}
                           </option>
                           {
                             internetConnections?.map((option) => (
                               <option key={option.id} value={option.id} dangerouslySetInnerHTML={{ __html: option.value }}/>
                             ))
                           }
                         </select>
                       </div>

                       { getFeedbackError(formik, 'internetType') }
                     </div>
                   </div>
                   <div className='col'>
                     <div className='hdb-input-group'>
                       <label htmlFor='accessibility'>
                         {translate('LABEL_ACCESSIBILITY_RESOURCE_REQUIRED')}
                         <Tooltip icon='hdb-icon--info-circle' information={translate('TOOLTIP_ACCESSIBILITY_RESOURCE_REQUIRED')}/>
                       </label>
                       <input
                         type='text'
                         name='accessibility'
                         id='accessibility'
                         data-id='ACCESSIBILITY'
                         className='hdb-input'
                         value={formik.values.accessibility}
                         onChange={formik.handleChange}
                         onBlur={formik.handleBlur}
                         placeholder={translate('LABEL_TYPE')}
                         required=''
                       />

                       { getFeedbackError(formik, 'accessibility') }
                     </div>
                   </div>
                 </div>
               </div>
               <div className='hdb-update-account__actions'>
                 <div className='hdb-update-account__left-actions'>
                   <button type='button' className='hdb-button hdb-button--transparent' onClick={() => setShowModalCancel(true)}>
                     {translate('BUTTON_CANCEL')}
                   </button>
                 </div>
                 <div className='hdb-update-account__right-actions'>
                   <button type='submit' className='hdb-button hdb-button--primary'>
                     {translate('BUTTON_SEND')}
                   </button>
                 </div>
               </div>
             </form>
           </div>
         </div>
       </div>
     </div>
    </div>

  );
};

export default wrapper(UpdateUserAccount, {
  namespace: 'USER_ACCOUNT',
  mapState: state => ({
    isAuthenticated: state.auth.isAuthenticated
  })
});

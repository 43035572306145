import React from 'react';
import { getAssetUrl } from '../../../helpers/strapiUtils';

const ConfirmDialog = ({ open, title, subtitle, textBody, buttonConfirm, buttonCancel , icon }) => {
  const modalClassActive = open ? 'hdb-modal-container--active' : null;

  return (
    <div className={'hdb-modal-container ' + modalClassActive}>
      <div className="hdb-modal">
        <div className="hdb-modal__header">
          <strong className="hdb-modal__title">
            <p>
              {title}  
            </p>
          </strong>
          <button className="hdb-modal__close" onClick={buttonCancel.onCancel}>
            <i className="hdb-icon hdb-icon--close"></i>
          </button>
        </div>

        <div className="hdb-modal__body">
          {icon ? <img src={getAssetUrl({data : {attributes: {url : icon}}})}  height="84" width="84" alt="Ícone de alerta" className="hdb-modal__icon" /> : null}
          <p>{subtitle}</p>
          <p className="hdb-modal__text">
            {textBody}
          </p>
        </div>

        <div className="hdb-modal__actions">
          <button type="button" className="hdb-button hdb-button--inverted" onClick={buttonCancel.onCancel}>
            {buttonCancel.name}
          </button>
          <button type="button" className="hdb-button hdb-button--primary" onClick={buttonConfirm.onConfirm}>
            {buttonConfirm.name}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;

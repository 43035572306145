import {http} from 'config/http/http';
import store from 'config/store/store';

import PublicContentService from './service';
import PublicContentUseCases from './useCases';

export const publicContentService = new PublicContentService({ http });

export const publicContentUseCases = new PublicContentUseCases({
  store,
  publicContentService
});

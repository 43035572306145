/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';

import wrapper from 'components/Wrapper';
import { publicContentService } from '../../../../services/providers/public-content';
import FormLoader from '../../../../components/Form/fragments/FormLoader';
import { getAssetUrl } from '../../../../helpers/strapiUtils';
import { authService, authUseCases } from '../../../../services/providers/auth';
import { NavLink, useLocation } from 'react-router-dom';
import { userUseCases } from '../../../../services/providers/user';
import DropdownMenu from '../../../../components/Menu/DropdownMenu';
import { PRIVATE_ROUTES } from '../../constants/routes';
import { getHostUrl, isRoutePublic } from '../../../../helpers/routeUtils';

const Header = ({ isAuthenticated })  => {
  
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  
  const [data, setData] = useState();
  
  const [showConstrast] = useState(false);
  
  const [showPublicRoutes, setShowPublicRoutes] = useState(false);
  
  const [showLogin, setShowLogin] = useState(true);

  const [loginEnabled, setLoginEnabled] = useState(true);
  
  const [user, setUser] = useState(null);
  
  const dropdownOptions = [
    { text: 'Meu Painel', link: `${PRIVATE_ROUTES.WELCOME_PAGE}`},
    { text: 'Trocar senha', link: `${PRIVATE_ROUTES.CHANGE_PASSWORD}`},
    { text: 'Sair', handleClick: () => {authUseCases.signOut();} }
  ];

  const [mobileDropdownOptions, setMobileDropdownOptions] = useState([]);

  const activeItemClass = 'hdb-header__link--active';

  const init = () => {
    getdata();
    setLoginEnabled(window.env.LOGIN_ENABLED);
  };

  useEffect(() => {
    if (isAuthenticated) {
      getInfoUser();
      setShowLogin(false);
    }
  }, [isAuthenticated]);

  const getInfoUser = async () => {
    setIsLoading(true);
    try {
      const userId = await authService.getSubFromTokenAsUserId();
      const response = await userUseCases.findUserAccount(userId);
      if (response) {
        setUser(response);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  }

  const getdata = async () => {
    setIsLoading(true);
    try {
      const response = await publicContentService.header();
      const content = await response.json();
      if (content.data?.attributes.menu) {
        setData(content.data?.attributes);
        setDropdownMenu(content.data?.attributes);
        handleItemClick();
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };
  
  const setDropdownMenu = (content) => {
    const menuOptions = [];

    content.menu.forEach((m) => {
      const opt = {
        text: m.texto,
        link: m.link
      };
      
      menuOptions.push(opt);
    });
    
    setMobileDropdownOptions({
      pageMenu: [
        ...menuOptions,
      ],
      userMenu: [
        ...dropdownOptions
      ],
      registerMenu: {
        text: content.textoBotaoCadastro,
        handleClick: register
      },
      signInMenu: {
        text: content.textoBotaoLogin,
        handleClick: signIn
      }
    });
  };

  const handleItemClick = () => {
    const menuItems = document.querySelectorAll('.hdb-header__link');
    for (const menuItem of menuItems) {
      menuItem.addEventListener('click', function(e) {
        const element = e.target.parentElement;
        cleanActiveItem();
        element.classList.add(activeItemClass);
      });
    }

    const menuLogo = document.querySelector('.hdb-header__logo');
    menuLogo.addEventListener('click', function(e) {
      cleanActiveItem();
    });
  };

  const cleanActiveItem = () => {
    const activeItem = document.querySelector(`.${activeItemClass}`);
    if (activeItem) {
      activeItem.classList.remove(activeItemClass);
    }
  };

  const signIn = () => {
    authUseCases.onPremiseSignIn(getHostUrl() + PRIVATE_ROUTES.WELCOME_PAGE);
  };

  const register = () => {
    authUseCases.register();
  };

  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  const increaseFontSize = () => {
    const htmlElement = document.getElementsByTagName("html")[0];
    
    if (htmlElement.style.fontSize) {
      const maxFontSize = 100.65;
      const newFontSize = parseFloat(htmlElement.style.fontSize) * 1.1;
      const fontSize = newFontSize < maxFontSize ? newFontSize : maxFontSize;
      htmlElement.style.fontSize = `${fontSize}%`;
    } else {
      const fontSize = 62.5 * 1.1;
      htmlElement.style.fontSize = `${fontSize}%`;
    }
  };

  const decreaseFontSize = () => {
    const htmlElement = document.getElementsByTagName("html")[0];

    if (htmlElement.style.fontSize) {
      const minFontSize = 36.90;
      const newFontSize = parseFloat(htmlElement.style.fontSize) * 0.9;
      const fontSize = newFontSize > minFontSize ? newFontSize : minFontSize;
      htmlElement.style.fontSize = `${fontSize}%`;
    } else {
      const fontSize = 62.5 * 1.1;
      htmlElement.style.fontSize = `${fontSize}%`;
    }
  };
  
  const handlePublicRoutes = () => {
    if (location.pathname) {
      const isPublicRoute = isRoutePublic(location.pathname);
      setShowPublicRoutes(isPublicRoute);

      const dropwdownMenuElement = document.querySelector('.hdb-dropdown-menu');
      const dropdownPrivateRouteClass = 'hdb-dropdown-menu--private-route';
      
      if (!isPublicRoute) {
        dropwdownMenuElement.classList.add(dropdownPrivateRouteClass);
      } else {
        dropwdownMenuElement.classList.remove(dropdownPrivateRouteClass);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    scrollTop();
    handlePublicRoutes();
  }, [location]);

  return (
    <header className='hdb-header'>
      {isLoading ? <FormLoader /> : null}
      
      <div className='container'>
        <nav className='hdb-header__nav'>
          <NavLink to={{pathname: '/'}} className='hdb-header__logo' title='Página inicial'>
            <img src={getAssetUrl(data?.logo.imagem)} alt='Logo Hackers do Bem' />
          </NavLink>
          <ul className='hdb-header__menu'>
          { showPublicRoutes
            ?
            data?.menu.map(menu => (
              <li key={`menu-${menu.id}`} className='hdb-header__link'>
                <NavLink to={{pathname: menu.link}} title={menu.texto}>
                  {menu.texto}
                </NavLink>
              </li>
            ))
            :
            null
          }
          </ul>
          <ul className='hdb-header__accessibility-controls'>
            <li>
              <a onClick={increaseFontSize}>
                <i className="hdb-icon hdb-icon--a-plus"></i>
              </a>
            </li>
            <li>
              <a onClick={decreaseFontSize}>
                <i className="hdb-icon hdb-icon--a-minus"></i>
              </a>
            </li>
            { showConstrast
              ?
              <li>
                <a>
                  <i className="hdb-icon hdb-icon--contrast"></i>
                </a>
              </li>
              :
              null
            }
          </ul>
          <ul className='hdb-header__auth-buttons'>
          { !isAuthenticated || !loginEnabled
            ?
            <li>
              <button className='hdb-button hdb-button--transparent' title={data?.textoBotaoCadastro} onClick={register}>
                {data?.textoBotaoCadastro}
              </button>
            </li>
            :
            null
          }
            
          { showLogin && !isAuthenticated && loginEnabled
            ?
            <li>
              <button className='hdb-button hdb-button--primary' onClick={signIn}>
                <span>{data?.textoBotaoLogin}</span>
                <FaArrowRight />
              </button>
            </li>
            :
              null
          }
          </ul>
          
          <DropdownMenu user={user} options={mobileDropdownOptions} isAuthenticated={isAuthenticated} showLogin={showLogin} loginEnabled={loginEnabled}/>
        </nav>
      </div>
    </header>
  );
};

export default wrapper(Header, {
  namespace: 'PUBLIC_HEADER',
  mapState: state => ({
    role: state.auth.role,
    isAuthenticated: state.auth.isAuthenticated
  })
});

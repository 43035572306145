/* eslint-disable react/prop-types */
import React from 'react';
import {Outlet} from "react-router-dom";

import PropTypes from 'prop-types';

import wrapper from 'components/Wrapper';

const AuthOutlet = () => {

  return (
    <>
      <Outlet />
    </>
  );
};

AuthOutlet.defaultProps = {
  role: undefined,
  isAuthenticated: false
};

AuthOutlet.propTypes = {
  role: PropTypes.string,
  isAuthenticated: PropTypes.bool
};

export default wrapper(AuthOutlet, {
  namespace: 'AUTH',
  mapState: state => ({
    role: state.auth.role,
    isAuthenticated: state.auth.isAuthenticated
  })
});

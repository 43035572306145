import React from 'react';
import { Outlet } from 'react-router-dom';
import wrapper from '../../../../components/Wrapper';
import Header from '../../fragments/Header';
import Footer from '../../fragments/Footer';

const PublicRoutesOutlet = () => (
  <>
    <Header />
    <main>
      <Outlet />
    </main>
    <Footer />
  </>
);

export default React.memo(wrapper(PublicRoutesOutlet, {
  
}));

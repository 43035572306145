import React from 'react';

import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from "rehype-raw";

import './style.scss';

function RichTextMarkdown({ markdown }) {
  
  return (
    <div className='hdb-rich-text-markdown'>
      <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
        {markdown}
      </ReactMarkdown>
    </div>
  );
}

RichTextMarkdown.defaultProps = {
  markdown: ''
};

RichTextMarkdown.propTypes = {
  markdown: PropTypes.string
};

export default RichTextMarkdown;


import {alpha, createTheme} from '@material-ui/core/styles';

import {FONT_FAMILY} from 'constants/fonts';

const baseTheme = createTheme({
  fontFamily: FONT_FAMILY,
  fontFeatureSettings: 'lnum',
  typography: {
    fontFamily: FONT_FAMILY,
    fontFeatureSettings: 'lnum',
  },
  palette: {
    primary: { main: '#1351b4', light: '#5992ed', dark: '#071d41' },
  },
});

const theme = createTheme(
  {
    props: {
      MuiCheckbox: {
        color: 'primary',
      },
      MuiRadio: {
        color: 'primary',
      },
      MuiButton: {
        variant: 'contained',
        color: 'primary',
      },
      MuiSwitch: {
        color: 'primary',
      },
    },
    overrides: {
      MuiTypography: {
        h6: {
          fontSize: '1.125rem',
          lineHeight: 1.375,
        },
      },
      MuiSwitch: {
        root: {
          width: 50,
          height: 26,
          padding: 0,
          margin: baseTheme.spacing(1),
        },
        colorPrimary: {
          padding: 3,
          color: baseTheme.palette.grey[500],
          '&$checked': {
            transform: 'translateX(24px)',
            color: baseTheme.palette.primary.main,
            '& + $track': {
              opacity: 1,
              backgroundColor: baseTheme.palette.common.white,
              borderColor: baseTheme.palette.grey[400],
            },
          },
        },
        thumb: {
          width: 20,
          height: 20,
          boxShadow: 'none',
        },
        track: {
          border: `1px solid ${baseTheme.palette.grey[400]}`,
          backgroundColor: baseTheme.palette.common.white,

          borderRadius: 26 / 2,
          opacity: 1,
        },
        checked: {},
        focusVisible: {},
      },
      MuiIconButton: {
        sizeSmall: {
          fontSize: '1rem',
          padding: '5px',
        },
        colorPrimary: {
          '&:hover': {
            backgroundColor: alpha(baseTheme.palette.primary.main, 0.1),
          },
        },
      },
      MuiInputAdornment: {
        root: {
          color: baseTheme.palette.primary.main,
          '& .MuiIconButton-root': {
            fontSize: '1rem',
            padding: '8px',
          },
        },
      },
      MuiDialogActions: {
        spacing: {
          padding: '12px 24px',
        },
      },
      MuiFormHelperText: {
        root: {
          color: baseTheme.palette.text.primary,
        },
      },
      MuiFormLabel: {
        root: {
          color: '#555555',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '19px',
          marginBottom: '5px',
        },
      },
      MuiInputLabel: {
        root: {
          fontWeight: 600,
        },
        shrink: {
          transform: 'translate(0, 2.5px) scale(1)',
        },
      },
      MuiCheckbox: { root: { padding: '5px', marginRight: '4px' } },
      MuiButton: {
        root: {
          textTransform: 'none',
        },
        contained: {
          borderRadius: '25px',
        },
        outlined: {
          borderRadius: '25px',
        },
      },
    },
  },
  baseTheme
);

const computedTheme = createTheme(
  {
    bgColors: {
      primaryPastel02: '#DBE8FB',
      primaryPastel01: '#C5D4EB',
      primaryLighten02: '#5992ED',
      primaryLighten01: '#2670E8',
      primaryDefault: '#1351B4',
      primaryDarken01: '#0C326F',
      primaryDarken02: '#071D41',
      colorSecondary01: '#FFFFFF',
      colorSecondary02: '#F8F8F8',
      colorSecondary03: '#EDEDED',
      colorSecondary04: '#CCCCCC',
      colorSecondary05: '#9E9D9D',
      colorSecondary06: '#888888',
      colorSecondary07: '#555555',
      colorSecondary08: '#333333',
      colorSecondary09: '#000000',
    },
    textColors: {
      primaryPastel02: '#333333',
      primaryPastel01: '#333333',
      primaryLighten02: '#FFFFFF',
      primaryLighten01: '#FFFFFF',
      primaryDefault: '#FFFFFF',
      primaryDarken01: '#FFFFFF',
      primaryDarken02: '#FFFFFF',
      colorSecondary01: '#333333',
      colorSecondary02: '#333333',
      colorSecondary03: '#333333',
      colorSecondary04: '#333333',
      colorSecondary05: '#333333',
      colorSecondary06: '#FFFFFF',
      colorSecondary07: '#FFFFFF',
      colorSecondary08: '#FFFFFF',
      colorSecondary09: '#FFFFFF',
    },
    highlightColors: {
      bgHighlight: '#268744',
      textHighlight: '#FFFFFF',
    },
    warningColors: {
      bgWarning: '#FFCD07',
      bgSuccess: '#168821',
      bgDanger: '#E60000',
      bgInfo: '#155BCB',
      txtWarning: '#333333',
      txtSuccess: '#FFFFFF',
      txtDanger: '#FFFFFF',
      txtInfo: '#FFFFFF',
    },
    supportColors: {
      support01: '#36A191',
      support02: '#F2E317',
      support03: '#DB4800',
      support04: '#A26739',
      support05: '#40E0D0',
      support06: '#48CBEB',
      support07: '#C72487',
      support08: '#63007F',
      support09: '#F08080',
      support10: '#FF8C00',
      support11: '#FDF5E6',
    },
    auxColors: {
      boxShadow: '#00000029',
      separator: '#707070',
      separatorHorizontal: '#D6D6D6',
    },
  },
  baseTheme,
  theme
);

export default computedTheme;

/* eslint-disable react/prop-types */
import React  from 'react';

import wrapper from 'components/Wrapper';

import './style.scss';
import PropTypes from 'prop-types';
import ImageAndTopics from '../../../../components/Strapi/ImageAndTopics';

const LevelsContent = ({ data }) => {

  return (
    <div className='hdb-levels-content'>
      <strong className='hdb-levels-content__title'>
        {data?.tituloConteudo}
      </strong>

      <div className='hdb-levels-content__list'>
        { data?.niveis?.map(item => (
          <ImageAndTopics key={`levels-${item.id}`} data={item} imgAuto={true} />
        ))}
      </div>
    </div>
  );
};

LevelsContent.defaultProps = {
  data: {
  }
};

LevelsContent.propTypes = {
  data: PropTypes.object
};

export default wrapper(LevelsContent, {
  namespace: 'LEVELS_CONTENT'
});

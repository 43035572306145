/* eslint-disable class-methods-use-this */
import {authService} from "../../services/providers/auth";

export class TokenManager {
  /**
   * Create a token manger
   *
   * @param {object} controllerProps
   */
  constructor({ storage }) {
    this.storage = storage;
  }

  /**
   */
  async getToken() {
    return await authService.getBearerToken();
  }
}

import React, { useState } from 'react';

import wrapper from 'components/Wrapper';

import { NEWS_ROUTES } from './constants/routes';
import NewsCarousel from './fragments/NewsCarousel';
import NewsSlider from './fragments/NewsSlider';
import NewsList from './fragments/NewsList';
import Breadcrumb from '../../components/Strapi/Breadcrumb';

import './style.scss';

const News = () => {
  const [breadcrumb] = useState([{
    name: 'Portal de Notícias',
    link: '/portal-de-noticias'
  }]);

  return (
    <div className='hdb-news'>

      <div className='hdb-blue-gradient-container'>
        <Breadcrumb routes={breadcrumb} />
        
        <div className='container'>
          <strong className='hdb-news__title'>Portal de Notícias</strong>
        </div>
        
        <NewsCarousel />
        
        <div className='container'>
          <NewsSlider isFeatureNews={true} />
          <NewsList />
        </div>
      </div>
    </div>
  );
};

export default wrapper(News, {
  route: { path: NEWS_ROUTES.NEWS }
});

/* eslint-disable no-return-await */

export default class UserUseCases {
  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   * @param {import('./service').default} props.userService
   */
  constructor({ store, userService }) {
    this.store = store;
    this.userService = userService;
  }

  async createAccount({ email, firstname, lastname, cpf }) {
    return await this.userService.createAccount({
      cpf,
      email,
      name: `${firstname} ${lastname}`
    });
  }

  async resetPassword(cpfOrEmail) {
    return await this.userService.resetPassword(cpfOrEmail);
  }

  async findUserAccount(userId) {
    return await this.userService.findUserAccountByUserId(userId);
  }
  async updateUserAccount(userAccount) {
    return await this.userService.updatedUserAccountByUserId(userAccount);
  }

  async forceResetPassword(id,password) {
    return await this.userService.forceResetPassword(id,password);
  }
}



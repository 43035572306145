import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';

import {HOME_PAGE_ROUTES} from './constants/routes';
import { publicContentService  } from '../../services/providers/public-content';
import FormLoader from '../../components/Form/fragments/FormLoader';

import Banner from '../../components/Strapi/Banner';
import TextAndImageBlock from '../../components/Strapi/TextAndImageBlock';
import TextAndVideoBlock from '../../components/Strapi/TextAndVideoBlock';
import IconAndText from '../../components/Strapi/IconAndText';
import FAQ from './fragments/FAQ';

import './style.scss';
import LearningPath from './fragments/ LearningPath';
import ApplicationProcess from './fragments/ApplicationProcess';
import NewsSlider from '../News/fragments/NewsSlider';

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  
  const init = () => {
    getData();
  };
  
  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await publicContentService.home();
      const content = await response.json();
      if (content.data) {
        setData(content.data.attributes);
      }
      hideLoader();
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  };

  const hideLoader = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  useEffect(() => {
    init();
  }, []);
  
  return (
    <div className='hdb-home'>
      {isLoading ? <FormLoader /> : null}
      
      <Banner data={data?.banner} isRegisterButton={true} />

      <div className='hdb-blue-gradient-container'>
        <div className='container'>
          <TextAndImageBlock data={data?.bloco01} />
          <TextAndVideoBlock data={data?.bloco02} />
          <IconAndText data={data?.diferenciais} />
        </div>
      </div>
      
      <div className='hdb-home__gradient'>
        <LearningPath data={data?.trilha} />
        <ApplicationProcess data={data?.processoInscricao} />
      </div>
      
      <div className='hdb-blue-gradient-container'>
        <div className='container'>
          <TextAndImageBlock data={data?.bloco03} />
          <NewsSlider title='Portal de Notícias' showAll={true} />
          <FAQ />
        </div>
      </div>
      
    </div>
  );
};

export default wrapper(HomePage, {
  route: { path: HOME_PAGE_ROUTES.HOME_PAGE }
});

import wrapper from 'components/Wrapper';
import './style.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import { useLangContext } from '../../../../components/Globalization';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../../App/constants/routes';
import { useEffect } from 'react';
import { authUseCases } from '../../../../services/providers/auth';

const WelcomePage = ({role, isAuthenticated}) => {
  const translate = useLangContext();
  
  const navigate = useNavigate();
  
  const openProfile = () => {
    navigate(PRIVATE_ROUTES.MY_PROFILE);
  };
  
  const openMoodle = () => {
    window.open(window.env.MOODLE_URL, '_blank');
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(PUBLIC_ROUTES.HOME_PAGE);
    } else {
      authUseCases.clearLoginRedirect();
    }
  }, [isAuthenticated]);

  return (
    <div className='hdb-welcome hdb-blue-gradient-container'>
      <div className='hdb-welcome__background-container'>
        <div className='container'>
          <h1>{translate('TITLE')}</h1>
          <p>{translate('SUBTITLE')}</p>

          <div className='hdb-welcome__card-container'>
            <div className='hdb-welcome__card' onClick={openProfile}>
              <strong>
                {translate('CARD_VISUALIZER_PROFILE_TITLE')}
              </strong>
              <img src='/assets/img/icon-user-circle-alt.png' height='84' />
              <p>
                {translate('CARD_VISUALIZER_PROFILE_DESCRIPTION')}
              </p>
              <NavLink to={PRIVATE_ROUTES.MY_PROFILE} title={translate('BUTTON_VISUALIZER_PROFILE')} className='hdb-button hdb-button--primary hdb-button--alternative'>
                {translate('BUTTON_VISUALIZER_PROFILE')}
              </NavLink>
            </div>
            <div className='hdb-welcome__card' onClick={openMoodle}>
              <strong>
                {translate('CARD_COURSE_PROFILE_TITLE')}
              </strong>
              <img src='/assets/img/icon_idea.png' height='84' />
              <p>
                {translate('CARD_COURSE_PROFILE_DESCRIPTION')}
              </p>
              <button type='button' className='hdb-button hdb-button--primary hdb-button--alternative' onClick={openMoodle}>
                {translate('BUTTON_COURSE_PROFILE')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default wrapper(WelcomePage, {
  namespace: 'WELCOME',
  mapState: state => ({
    role: state.auth.role,
    isAuthenticated: state.auth.isAuthenticated
  })
});

import React from 'react';

import PropTypes from 'prop-types';

import { getAssetUrl } from '../../../helpers/strapiUtils';

import './style.scss';
import { FaArrowRight } from 'react-icons/fa';

function TextAndImageBlock({ data, imgAuto }) {
  
  const IMAGE_POSITION = {
    LEFT: 'Esquerda',
    RIGHT: 'Direita'
  };

  return (
    <div className={`hdb-text-image-block ${imgAuto ? 'hdb-text-image-block--img-auto': null}`}>
      { data?.posicaoImagem == IMAGE_POSITION.LEFT
        ?
        <div className='hdb-text-image-block__left-image'>
          <img src={getAssetUrl(data?.imagem)} />
        </div>
        :
        null
      }

      { data?.imagemEsquerda
        ?
        <div className='hdb-text-image-block__left-image'>
          <img src={getAssetUrl(data?.imagemEsquerda)} />
        </div>
        :
        null
      }
      
      <div className='hdb-text-image-block__text'>
        <small className='hdb-text-image-block__subtitle'>
          {data?.subtitulo}
        </small>
        <strong className='hdb-text-image-block__title'>
          {data?.titulo}
        </strong>
        <span className='hdb-text-image-block__description' dangerouslySetInnerHTML={{__html: data?.descricao}}></span>

        { data?.mostrarBotaoSaibaMais
          ?
          <a href={data?.linkSaibaMais} className='hdb-text-image-block__more hdb-link' title='Saiba mais'>
            <span>Saiba mais</span>
            <FaArrowRight />
          </a>
          :
          null  
        }
      </div>
      
      { data?.posicaoImagem == IMAGE_POSITION.RIGHT
        ?
        <div className='hdb-text-image-block__right-image'>
          <img src={getAssetUrl(data?.imagem)} />
        </div>
        :
        null
      }

      { data?.imagemDireita
        ?
        <div className='hdb-text-image-block__right-image'>
          <img src={getAssetUrl(data?.imagemDireita)} />
        </div>
        :
        null
      }
    </div>
  );
}

TextAndImageBlock.defaultProps = {
  data: {},
  imgAuto: false
};

TextAndImageBlock.propTypes = {
  data: PropTypes.object,
  imgAuto: PropTypes.bool
};

export default TextAndImageBlock;

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { FaArrowRight, FaEnvelope } from 'react-icons/fa';
import wrapper from 'components/Wrapper';
import { publicContentService } from '../../../../services/providers/public-content';
import FormLoader from '../../../../components/Form/fragments/FormLoader';

import { getAssetUrl } from '../../../../helpers/strapiUtils';
import Link from '../../../../components/Strapi/Link';
import { NavLink, useLocation } from 'react-router-dom';
import { PUBLIC_ROUTES } from '../../constants/routes';
import { useLangContext } from '../../../../components/Globalization';
import { isRoutePublic } from '../../../../helpers/routeUtils';

const Footer = () => {
  
  const translate = useLangContext();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  
  const [data, setData] = useState();
  
  const [showNewsletter] = useState(false);

  const [showPublicRoutes, setShowPublicRoutes] = useState(false);

  const init = () => {
    getData();
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await publicContentService.footer();
      const content = await response.json();
      if (content.data?.attributes) {
        setData(content.data?.attributes);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const handlePublicRoutes = () => {
    if (location.pathname) {
      setShowPublicRoutes(isRoutePublic(location.pathname));
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    handlePublicRoutes();
  }, [location]);

  return (
    <footer className='hdb-footer'>
      {isLoading ? <FormLoader /> : null}

      <div className={`hdb-footer__links ${!showPublicRoutes ? 'hdb-footer__links--text-center' : null}`}>
        { showPublicRoutes
          ?
          <div className='hdb-footer__divider'></div>
          :
          null
        }
        <div className='container'>
          <div className='hdb-footer__top'>
            { showPublicRoutes
            ?
              <>
                <a href='src/modules/App/fragments/Footer' className='hdb-footer__logo' title='Página inicial'>
                  <img src={getAssetUrl(data?.logo.imagem)} alt='Logo Hackers do Bem' />
                </a>
                <nav className='hdb-footer__menu'>
                  {data?.menuGrupo.map(menuGrupo => (
                    <div key={`menu-grupo-${menuGrupo.id}`} className='hdb-footer__menu-grupo'>
                      <strong>
                        {menuGrupo.titulo}
                      </strong>

                      <ul>
                        {menuGrupo.itemMenu.map(menuItem => (
                          <li key={`menu-item-${menuItem.id}`}>
                            <Link link={menuItem} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </nav>
              </>
            :
              <p className='hdb-footer__privacy-notice'>{translate('TEXT')} <strong><NavLink to={PUBLIC_ROUTES.PRIVACY_NOTICE}>{translate('PRIVACY_NOTICE')}</NavLink></strong></p>
            }
          </div>

          { showPublicRoutes
            ?
            <>
              { showNewsletter
                ?
                <div className='hdb-footer__newsletter'>
                  <p>
                    {data?.newsletter.texto}
                  </p>
                  <div className='hdb-input-group'>
                    <div className='hdb-input-group__icon-group'>
                      <div className='hdb-input-group__prepend'>
                        <FaEnvelope />
                      </div>
                      <input type='email' name='email' id='email' className='hdb-input' placeholder={data?.newsletter.placeholder} />
                      <div className='hdb-input-group__append' data-show-password='true'>
                        <span>{data?.newsletter.textoBotao}</span>
                        <FaArrowRight />
                      </div>
                    </div>
                  </div>
                </div>
                :
                null
              }

              <div className='hdb-footer__divider'></div>
            </>
            :
            null
          }

          { showPublicRoutes
            ?
            <div className='hdb-footer__bottom'>
              <div className='hdb-footer__copyright'>
                {data?.copyright}
              </div>

              <div className='hdb-footer__share-buttons'>
                <span>{data?.compartilharComRedes.titulo}</span>

                {data?.compartilharComRedes.socialLink.map(socialLink => (
                  <a key={`social-link-${socialLink.id}`} href={socialLink.link} title={socialLink.label}>
                    <img src={getAssetUrl(socialLink.icone)} alt='Logo Hackers do Bem' />
                  </a>
                ))}
              </div>
            </div>
            :
            null
          }
        </div>
      </div>
      
      <div className='hdb-footer__partners'>
        <img src={getAssetUrl(data?.reguaParceiros)} alt='HDB - Parceiros' />
        <p>
          {data?.textoParceiros}
        </p>
      </div>
    </footer>
  );
};

export default wrapper(Footer, {
  namespace: 'PUBLIC_FOOTER'
});

import React from 'react';

import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import { authUseCases } from '../../../services/providers/auth';

function Link({ link }) {

  const LINK_TYPES = {
    INTERNAL_LINK: 'Link interno',
    EXTERNAL_LINK: 'Link externo',
    LOGIN: 'Login',
    REGISTER: 'Cadastro',
    FAQ: 'FAQ',
    E_MAIL: 'E-mail',
    TEXT: 'Texto'
  };

  const openSignIn = () => {
    authUseCases.onPremiseSignIn();
  };

  const openRegister = () => {
    authUseCases.register();
  };

  return (
    <>
      { link.tipoNavegacao === LINK_TYPES.INTERNAL_LINK
        ?
        <NavLink to={{pathname: link.link}} title={link.texto}>
          {link.texto}
        </NavLink>
        :
        null
      }
      
      { link.tipoNavegacao === LINK_TYPES.EXTERNAL_LINK
        ?
        <a href={link.link} title={link.texto} target='_blank'>
          {link.texto}
        </a>
        :
        null
      }

      { link.tipoNavegacao === LINK_TYPES.LOGIN
        ?
        <a title={link.texto} onClick={openSignIn}>
          {link.texto}
        </a>
        :
        null
      }

      { link.tipoNavegacao === LINK_TYPES.REGISTER
        ?
        <a title={link.texto} onClick={openRegister}>
          {link.texto}
        </a>
        :
        null
      }

      { link.tipoNavegacao === LINK_TYPES.FAQ
        ?
        <a href='/#hdb-faq' title={link.texto}>
          {link.texto}
        </a>
        :
        null
      }

      { link.tipoNavegacao === LINK_TYPES.E_MAIL
        ?
        <a href={`mailto:${link.link}`} title={link.texto}>
          {link.texto}
        </a>
        :
        null
      }

      { link.tipoNavegacao === LINK_TYPES.TEXT
        ?
        <span>
          {link.texto}
        </span>
        :
        null
      }
    </>
  );
}

Link.defaultProps = {
  data: {}
};

Link.propTypes = {
  link: PropTypes.object
};

export default Link;

/* eslint-disable react/prop-types */
import React from 'react';

import wrapper from 'components/Wrapper';

import './style.scss';
import PropTypes from 'prop-types';
import { FaChevronDown, FaCircle, FaMinus } from 'react-icons/fa';

const ContentAccordion = ({ data }) => {

  return (
    <div className='hdb-content-accordion'>
      <div className='hdb-accordion'>
        <input type="checkbox" id={`content-${data.id}`} name={`content-${data.id}`} />
        <label htmlFor={`content-${data.id}`} className="hdb-accordion__collapse">
          <strong className='hdb-accordion__title'>
            {data?.titulo}
          </strong>
          <span className='hdb-accordion__subtitle'>
            {data?.tempo}
          </span>
          <span className='hdb-accordion__open-icon'>
            <FaChevronDown />
          </span>
          <span className='hdb-accordion__close-icon'>
            <FaMinus />
          </span>
        </label>
        <div className='hdb-accordion__content'>
          <div className='row'>
            { data?.grupoConteudo?.map(item => (
              <div key={`gc-${item.id}`} className={`${item.titulo ? 'col-12 col-sm-12 col-md-12 col-lg-4 mb-6': 'row'}`}>
                { item.titulo 
                ?
                  <div className='hdb-content-accordion__group-title'>
                    {item.titulo}
                  </div>
                :
                  null
                }

                { item?.topicos?.map(topic => (
                  <>
                    { !item.titulo
                      ?
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                          <div key={`tp-${topic.id}`} className='hdb-content-accordion__item'>
                            <FaCircle />
                            <span>{topic.texto}</span>
                          </div>
                        </div>
                      :
                        <div key={`tp-${topic.id}`} className='hdb-content-accordion__item'>
                          <FaCircle />
                          <span>{topic.texto}</span>
                        </div>
                    }
                  </>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

ContentAccordion.defaultProps = {
  data: {}
};

ContentAccordion.propTypes = {
  data: PropTypes.object
};

export default wrapper(ContentAccordion, {
  namespace: 'CONTENT_ACCORDION'
});

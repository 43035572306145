import {KEYCLOAK_API} from './api';

export default class KeycloakService {
  
  /**
   * @param {object} props
   // * @param {import('config/http/axios').Axios} props.http
   */
  constructor({ http }) {
    this.http = http;
  }
  

  async genders() {
    return this.http.get(KEYCLOAK_API.GENDERS);
  }
  async scholarities() {
    return this.http.get(KEYCLOAK_API.SCHOLARITIES);
  }
  async internetConnection() {
    return this.http.get(KEYCLOAK_API.INTERNET_CONNECTIONS);
  }
}

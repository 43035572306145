import { ZIP_CODE_API } from './api';
import { StringUtil } from '@app-starter/rnp-core';

export default class ZipCodeService {
  
  constructor() {
    this.baseUrl = 'https://viacep.com.br/ws/';
  }
  
  async get(zipCode) {
    const url = this.baseUrl + StringUtil.formatString(ZIP_CODE_API.GET, { zipCode });
    return fetch(url
      ,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
  }
}

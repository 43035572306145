import store from 'config/store/store';

import ZipCodeService from './service';
import ZipCodeUseCases from './useCases';

export const zipCodeService = new ZipCodeService();

export const zipCodeUseCases = new ZipCodeUseCases({
  store,
  zipCodeService
});

const developmentEnv =
  window.env.ENV === 'development' ||
  process.env.NODE_ENV === 'development';

export const isDevelopment = run => {
  if (developmentEnv && run) {
    run();
  }
  return developmentEnv;
};

export const recaptchaEnabled = !window.env.RECAPTCHA_DISABLED;

import {authActions} from 'config/store/slices/auth.slice';
import jwt_decode from 'jwt-decode';
import {authService} from './index';
import store from '../../../config/store/store';
import { PRIVATE_ROUTES } from '../../../modules/App/constants/routes';
import { getHostUrl } from '../../../helpers/routeUtils';
import { LocalStorage } from '@app-starter/rnp-core';

export default class AuthUseCases {

  GOV_BR_PROVIDER_ID = 'Gov.BR';

  LOGIN_REDIRECT_KEY = 'LOGIN_REDIRECT';

  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   * @param {import('services/providers/user/service').default} props.userService
   * @param {import('services/providers/auth/service').default} props.authService
   */
  constructor({ store, authService, userService }) {
    this.store = store;
    this.authService = authService;
    this.userService = userService;
    this.localStorage = new LocalStorage();
  }

  onPremiseSignIn(redirectUri) {
    this.authService.onPremiseSignIn(redirectUri);
    if (redirectUri && redirectUri !== '') {
      this.saveLoginRedirect();
    }
  }

  onPremiseSignOut(redirectUri) {
    this.signOut(redirectUri);
  }
  
  register() {
    this.authService.register();
  }

  async continueOnPremiseSignIn(isAuthenticated) {
    store.dispatch(authActions.isAuthenticated(isAuthenticated));
    const session = {};
    authService.unsetMeFailedFlag();
    try {
      authService.unsetMeFailedFlag();
      const user = { };
      this.store.dispatch(authActions.signIn({ user, session }));
    } catch (error) {
      console.log('SignIn Failed!', error);
      authService.setMeFailedFlag();
      await this.checkGovBrSession();
    }
  }

  async accessTokenLogin(jwt, token, username) {
    const { user, session } = await this.authService.accessTokenLogin(jwt, token, username);
    await this.signIn(session);
    return { user, session };
  }

  async signIn(session) {
    if (!session) return;
    try {
      authService.unsetMeFailedFlag();
      const token = jwt_decode(this.authService.getBearerToken().replaceAll("Bearer"));
      const user = { id: token.subject, name:  token.name};
      this.store.dispatch(authActions.signIn({ user, session }));
    } catch (error) {
      console.log('SignIn Failed!', error);
      authService.setMeFailedFlag();
      await this.checkGovBrSession();
    }
  }

  async getMe() {
    const { isAuthenticated } = this.store.getState().auth

    if (isAuthenticated) {
      try {
        authService.unsetMeFailedFlag();
        return await this.userService.me();
      } catch (error) {
        console.log('[getMe-ERROR]', error);
        authService.setMeFailedFlag();
        await this.checkGovBrSession();
        return null
      }
    }

    return null;
  }

  async updateCurrentUserInformation() {
    const user = await this.getMe();
    if (user) this.store.dispatch(authActions.me(user));
  }

  async signOut(redirectUri) {
    const session = await this.authService.restoreUserSession();

    if (session) {
      if (this.getAuthProvider(session) === this.GOV_BR_PROVIDER_ID) {
        this.authService.govBrSignOut();
      } else {
        authService.unsetIsWaitingGovBrAuthLogout();
        store.dispatch(authActions.isWaitingGovBrLogout(false));
      }
    }

    if (redirectUri) {
      await this.authService.signOut(redirectUri);
    } else {
      await this.authService.signOut(getHostUrl());
    }

    authService.clearAuthStorage();
    
    this.clearLoginRedirect();

    this.store.dispatch(authActions.signOut());
  }

  async restoreCurrentSession() {
    const session = await this.authService.restoreUserSession();
    if (session) {
      await this.signIn(session);
    }
  }

  async forceLogout() {
    this.store.dispatch(authActions.signOut());
  }

  async checkGovBrSession() {
    const session = await this.authService.restoreUserSession();

    if (session) {
      if (this.getAuthProvider(session) === this.GOV_BR_PROVIDER_ID) {
        authService.setIsWaitingGovBrAuthLogout();
        store.dispatch(authActions.isWaitingGovBrLogout(true));
      } else {
        authService.unsetIsWaitingGovBrAuthLogout();
        store.dispatch(authActions.isWaitingGovBrLogout(false));
      }
    }
  }

  getAuthProvider(session) {
    const jwtDecoded = jwt_decode(session.idToken.jwtToken);
    const { identities } = jwtDecoded;
    return identities && identities[0] && identities[0].providerName;
  }

  async getFullToken(code) {
    await this.authService.getFullToken(code);
  }
  
  saveLoginRedirect() {
    this.localStorage.set(this.LOGIN_REDIRECT_KEY, PRIVATE_ROUTES.WELCOME_PAGE);
  }

  getLoginRedirect() {
    return this.localStorage.get(this.LOGIN_REDIRECT_KEY);
  }

  clearLoginRedirect() {
    this.localStorage.remove(this.LOGIN_REDIRECT_KEY);
  }
  
  cleanSignOut() {
    authService.clearAuthStorage();

    this.clearLoginRedirect();

    this.store.dispatch(authActions.signOut());
  }
}

import React from 'react';

import PropTypes from 'prop-types';

import './style.scss';
import { authUseCases } from '../../../services/providers/auth';

function TitleSubtitleButton({ title, subtitle, buttonText, buttonLink, align, color, size, isRegisterButton, invertText }) {
  
  const onClick = () => {
    if (isRegisterButton) {
      authUseCases.register();
    }
  };

  return (
    <div className={`hdb-title-subtitle-button hdb-title-subtitle-button--${align} hdb-title-subtitle-button--${color} hdb-title-subtitle-button--${size} ${invertText ? 'hdb-title-subtitle-button--alternative' : ''}`}>
      { invertText
        ?
        <>
          { subtitle
            ?
            <p>
              {subtitle}
            </p>
            :
            null
          }
          
          { title
            ?
            <h1>
              {title}
            </h1>
            :
            null
          }
        </>
        :
        <>
          { title
            ?
            <h1>
              {title}
            </h1>
            :
            null
          }
          { subtitle
            ?
            <p>
              {subtitle}
            </p>
            :
            null
          }
        </>
      }
      
      { buttonText && !buttonLink
        ?
        <button className='hdb-button hdb-button--primary hdb-button--xl' onClick={onClick}>
          {buttonText}
        </button>
        :
        null
      }
      
      { buttonText && buttonLink
        ?
        <a className='hdb-button hdb-button--primary hdb-button--xl'  href={buttonLink} target='_blank'>
          {buttonText}
        </a>
        :
        null
      }
    </div>
  );
}

TitleSubtitleButton.defaultProps = {
  title: null,
  subtitle: null,
  buttonText: null,
  align: 'left',
  color: 'dark',
  size: 'md',
  isRegisterButton: false,
  invertText: false
};

TitleSubtitleButton.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  align: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  isRegisterButton: PropTypes.bool,
  invertText: PropTypes.bool
};

export default TitleSubtitleButton;


import React from 'react';

import wrapper from 'components/Wrapper';

import {INITIAL_PAGE_ROUTES} from './constants/routes';
import {Box, Typography} from '@material-ui/core';

const InitialPage = () => (
  <Box mt={10}>
    <Typography variant="h4" align="center">
      Bem vindo ao projeto appstarter-exemplo
    </Typography>
  </Box>
);

export default wrapper(InitialPage, {
  route: { path: INITIAL_PAGE_ROUTES.INITIAL_PAGE }
});

import React from 'react';

import PropTypes from 'prop-types';

import { FaChevronDown, FaMinus } from 'react-icons/fa';

function SimpleAccordion({ id, name, title, description }) {
  
  return (
    <div className='hdb-accordion'>
      <input type="checkbox" id={`${name}-${id}`} name={`${name}-${id}`} />
      <label htmlFor={`${name}-${id}`} className="hdb-accordion__collapse">
        <span className='hdb-accordion__title'>{title}</span>
        <span className='hdb-accordion__open-icon'>
          <FaChevronDown />
        </span>
        <span className='hdb-accordion__close-icon'>
          <FaMinus />
        </span>
      </label>
      <div className='hdb-accordion__content'>
        <p dangerouslySetInnerHTML={{__html: description}}></p>
      </div>
    </div>
  );
}

SimpleAccordion.defaultProps = {
  id: 0,
  name: '',
  title: '',
  description: ''
};

SimpleAccordion.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
};

export default SimpleAccordion;


export default class KeycloakUseCases {
  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   * @param {import('./service').default} props.keycloakService
   */
  constructor({ store, keycloakService }) {
    this.store = store;
    this.keycloakService = keycloakService;
  }
  
  async genders() {
    return await this.keycloakService.genders();
  }
  async scholarities() {
    return await this.keycloakService.scholarities();
  }
  async internetConnection() {
    return await this.keycloakService.internetConnection();
  }
  
}

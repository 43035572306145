/* Reference
 * https://github.com/i18next/react-i18next/blob/master/example/locize/src/i18n.js
 */

import {initReactI18next} from 'react-i18next';

import i18n from 'i18next';

import {isDevelopment} from 'helpers/environment';

import {resources} from '../../assets/lang';

i18n.use(initReactI18next).init({
  lng: 'ptBR',
  fallbackLng: 'ptBR',
  debug: isDevelopment(),
  resources,
  saveMissing: true,
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: 'languageChanged editorSaved',
  },
});

export default i18n;

import {http} from 'config/http/http';
import store from 'config/store/store';

import KeycloakService from './service';
import KeycloakUseCases from './useCases';

export const keycloakService = new KeycloakService({ http });

export const keycloakUseCases = new KeycloakUseCases({
  store,
  keycloakService
});

import React from 'react';

import PropTypes from 'prop-types';

import { getAssetUrl } from '../../../helpers/strapiUtils';

import './style.scss';

function IconAndText({ data }) {
  
  return (
    <div className='hdb-icon-text-block'>
      <div className='hdb-icon-text-block__list'>
        { data?.map(item => (
          <div key={`icon-text-${item.id}`} className='hdb-icon-text-block__item'>
            <img src={getAssetUrl(item.icone)} className='hdb-icon-text-block__icon' />
            <strong className='hdb-icon-text-block__title'>
              {item.titulo}
            </strong>
            <p className='hdb-icon-text-block__description'>
              {item.descricao}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

IconAndText.defaultProps = {
  data: []
};

IconAndText.propTypes = {
  data: PropTypes.array
};

export default IconAndText;


/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';
import { publicContentService } from '../../../../services/providers/public-content';
import FormLoader from '../../../../components/Form/fragments/FormLoader';
import SimpleAccordion from '../../../../components/Strapi/SimpleAccordion';

import './style.scss';

const FAQ = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  
  const limit = 5;

  const init = () => {
    getData();
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await publicContentService.faqs();
      const content = await response.json();
      if (content.data) {
        setData(content.data);
        setPaginatedData(content.data.slice(0,5));
        setMaxPage(Math.floor(Math.ceil(content.data?.length / limit)));
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };
  
  const loadMore = (data, page) => {
    setPage(2);
    setPaginatedData(data.slice(0, page * limit));
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div id='hdb-faq' className='hdb-faq'>
      {isLoading ? <FormLoader /> : null}

      <strong className='hdb-faq__title'>
        FAQ
      </strong>

      <div className='hdb-faq__list'>

        { paginatedData?.map(item => (
          <SimpleAccordion key={`faq-${item.id}`} id={item.id} name='faq' title={item.attributes.titulo} description={item.attributes.descricao}></SimpleAccordion>
        ))}

        { data.length > 5
          ?
            <button className='hdb-button hdb-button--transparent hdb-faq__more' title='Carregar mais' onClick={() => {loadMore(data, page+1)}} disabled={page >= maxPage}>
              <span>Carregar mais</span>
            </button>
          :
            null
        }
      </div>
    </div>
  );
};

export default wrapper(FAQ, {
  namespace: 'FAQ'
});

/* eslint-disable react/prop-types */
import React from 'react';

import wrapper from 'components/Wrapper';
import PropTypes from 'prop-types';

import './style.scss';

const ApplicationProcess = ({ data }) => {

  return (
    <div className='hdb-application-process'>
      <div className='container'>
        <strong className='hdb-application-process__title'>
          {data?.titulo}
        </strong>
        <div className='hdb-application-process__steps'>

          { data?.passosInscricao.map((item, index) => (
            <div key={`ap-step-${item.id}`} className='hdb-application-process__step'>
              <div className='hdb-application-process__step-top'>
                <span className='hdb-application-process__step-number'>
                  {index+1}
                </span>
                <strong className='hdb-application-process__step-title'>
                  {item.titulo}
                </strong>
              </div>
              <p className='hdb-application-process__step-description' dangerouslySetInnerHTML={{__html: item.descricao}}></p>
            </div>
          ))}
          
        </div>
      </div>
    </div>
  );
};

ApplicationProcess.defaultProps = {
  data: {
    passosInscricao: []
  }
};

ApplicationProcess.propTypes = {
  data: PropTypes.object
};

export default wrapper(ApplicationProcess, {
  namespace: 'APPLICATION_PROCESS'
});

/* eslint-disable no-return-await */
export default class PublicContentUseCases {
  /**
   * @param {object} props
   * @param {import('redux-starter-kit').Store} props.store
   * @param {import('./service').default} props.publicContentService
   */
  constructor({ store, publicContentService }) {
    this.store = store;
    this.publicContentService = publicContentService;
  }

  async home() {
    return await this.publicContentService.home();
  }
}

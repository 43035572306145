import React, {useEffect} from 'react';
import {useLangContext} from 'components/Globalization';

import {APP_ROUTES} from 'modules/App/constants/routes';
import Logout from 'modules/Auth/fragments/Logout';

import {NotAllowedStyled} from './style';
import {authService} from 'services/providers/auth';
import {Box} from "@material-ui/core";
import wrapper from "../../../../components/Wrapper";

const NoAccess = ({ meFailed }) => {
  const translate = useLangContext();

  useEffect(() => {
    authService.unsetMeFailedFlag();
  }, [meFailed]);
  
  return (<Box mt={10}>
    <NotAllowedStyled>
      <div>
        <h1>{translate('ACCESS_NOT_ALLOWED')}</h1>
        <Logout label={translate('ACCESS_HOME_PAGE')}/>
      </div>
    </NotAllowedStyled>
  </Box>);
};

export default wrapper(NoAccess, {
  namespace: 'NO_ACCESS',
  route: {
    path:  APP_ROUTES.NOT_ALLOWED
  },
  mapState: state => ({
    meFailed: state.auth.meFailed,
  }),
});

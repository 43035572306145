import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';
import { publicContentService } from '../../services/providers/public-content';
import FormLoader from '../../components/Form/fragments/FormLoader';
import Breadcrumb from '../../components/Strapi/Breadcrumb';

import './style.scss';
import RichTextMarkdown from '../../components/Strapi/RichTextMarkdown';

const PrivacyNotice = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  
  const [breadcrumb] = useState([{
    name: 'Aviso de Privacidade',
    link: '/aviso-de-privacidade'
  }]);

  const init = () => {
    getData();
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await publicContentService.privacyNotice();
      const content = await response.json();
      if (content.data) {
        setData(content.data.attributes);
      }
      hideLoader();
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  };

  const hideLoader = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='hdb-privacy-notice'>
      {isLoading ? <FormLoader /> : null}

      <div className='hdb-blue-gradient-container'>
        <Breadcrumb routes={breadcrumb} />

        <div className='container'>
          <div className='hdb-privacy-notice__content'>
            <strong className='hdb-privacy-notice__title'>
              {data?.titulo}
            </strong>
            <div className='hdb-privacy-notice__intro'>
              <RichTextMarkdown markdown={data?.intro} />
            </div>
            {data?.secoes.map(section => (
              <div key={`nsec-${section.id}`} className='hdb-privacy-notice__section'>
                <strong>{section?.titulo}</strong>
                <RichTextMarkdown markdown={section?.descricao} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default wrapper(PrivacyNotice, {
});

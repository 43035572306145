import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';

import banner from 'assets/img/hdb-banner-em-breve-noticias.svg';
import { FaArrowRight, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { publicContentService } from '../../../../services/providers/public-content';
import { getAssetUrl } from '../../../../helpers/strapiUtils';
import { NavLink } from 'react-router-dom';
import FormLoader from '../../../../components/Form/fragments/FormLoader';
import RichTextMarkdown from '../../../../components/Strapi/RichTextMarkdown';

import './style.scss';

const NewsCarousel = () => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  
  const indicatorActiveClass = 'hdb-news-carousel__indicator--active';

  const init = () => {
    getData();
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const responseFeatureNews = await publicContentService.featureNews();
      const contentFeatureNews = await responseFeatureNews.json();
      
      let news = [];
      let featureNewsArray = [];
      let newsArray = [];
      
      if (contentFeatureNews.data) {
        featureNewsArray = contentFeatureNews.data.slice(0, 3);
      }

      if (!featureNewsArray.length || featureNewsArray.length < 3) {
        const responseNews = await publicContentService.news();
        const contentNews = await responseNews.json();

        if (contentNews.data) {
          newsArray = contentNews.data.slice(0, (3 - featureNewsArray.length));
          news = [...featureNewsArray, ...newsArray];
        }
      } else {
        news = [...featureNewsArray];
      }

      setData(news);
      initSlider();
      hideLoader();
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  };

  const initSlider = () => {
    const slider = document.querySelector('.hdb-news-carousel__slider');
    const item = slider.querySelector('.hdb-news-carousel__item');
    const leftButton = document.querySelector('.hdb-news-carousel__previous');
    const rightButton = document.querySelector('.hdb-news-carousel__next');

    const indicators = document.querySelectorAll('.hdb-news-carousel__indicator');

    const sliderWidth = slider.offsetWidth;
    const itemStyle = item.currentStyle || window.getComputedStyle(item)
    const itemMarginRight = Number(itemStyle.marginRight.match(/\d+/g)[0]);

    const itemCount = slider.querySelectorAll('.hdb-news-carousel__item').length;

    let offset = 0;
    const maxX = -(itemCount * sliderWidth + (itemMarginRight * itemCount) - sliderWidth - itemMarginRight);

    leftButton.addEventListener('click', function() {
      if (offset !== 0) {
        offset += sliderWidth + itemMarginRight;
        slider.style.transform = `translateX(${offset}px)`;
        
        const activeIndex = Math.floor((Math.abs(offset) + itemMarginRight) / sliderWidth);
        const indicator = document.querySelector(`.hdb-news-carousel__indicator[data-index='${activeIndex}']`);

        cleanActiveIndicator();

        indicator.classList.add(indicatorActiveClass);
      }
    });

    rightButton.addEventListener('click', function() {
      if (offset !== maxX && Math.abs(offset) < Math.abs(maxX)) {
        offset -= sliderWidth + itemMarginRight;
        slider.style.transform = `translateX(${offset}px)`;
        
        const activeIndex = Math.floor((Math.abs(offset) - itemMarginRight) / sliderWidth);
        const indicator = document.querySelector(`.hdb-news-carousel__indicator[data-index='${activeIndex}']`);

        cleanActiveIndicator();
        
        indicator.classList.add(indicatorActiveClass);
      }
    });

    for (const indicator of indicators) {
      indicator.addEventListener('click', function(e) {
        offset = 0;
        
        const element = e.target;
        
        cleanActiveIndicator();
        
        element.classList.add(indicatorActiveClass);
        
        const scrollTo = parseInt(element.dataset.index, 10);

        if (offset !== maxX) {
          offset -= (sliderWidth+itemMarginRight)*scrollTo;
          slider.style.transform = `translateX(${offset}px)`;
        }
      });
    }

    indicators[0].classList.add(indicatorActiveClass);
  };
  
  const cleanActiveIndicator = () => {
    const activeIndicator = document.querySelector(`.${indicatorActiveClass}`);
    if (activeIndicator) {
      activeIndicator.classList.remove(indicatorActiveClass);
    }
  };

  const hideLoader = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {isLoading ? <FormLoader /> : null}
      
      <div className='hdb-news-carousel'>
        { data.length > 1
          ?
          <button className='hdb-news-carousel__previous'>
            <FaChevronLeft />
          </button>
          :
          null
        }
        
        <ul className='hdb-news-carousel__slider'>
          { data && data.length > 0
          ?
            <>
              {data.map((news, index) => (
                <li key={`nc-${news.id}`} className='hdb-news-carousel__item' data-index={index}>
                  <div className='hdb-news-carousel__content'>
                    <img src={getAssetUrl(news.attributes.imagemDeDestaque.imagem)} alt={news.attributes.titulo} />
                    <div className='hdb-news-carousel__info'>
                      <strong className='hdb-news-carousel__title'>
                        {news.attributes.titulo}
                      </strong>
                      <div className='hdb-news-carousel__subtitle'>
                        <RichTextMarkdown markdown={news.attributes.resumo} />
                      </div>
                    </div>
                    <NavLink to={`/portal-de-noticias/${news.attributes.slug}`} className='hdb-button hdb-button--primary'>
                      <span>Saiba Mais</span>
                      <FaArrowRight />
                    </NavLink>
                  </div>
                </li>
              ))}
            </>
          :
            <li className='hdb-news-carousel__item'>
              <div className='hdb-news-carousel__content'>
                <img src={banner} alt='Em breve, notícias.' />
              </div>
            </li>
          }
        </ul>

        { data.length > 1
          ?
          <>
            <ol className='hdb-news-carousel__indicators'>
              {data.map((news, index) => (
                <li key={`ci-${news.id}`} className='hdb-news-carousel__indicator' data-index={index}></li>
              ))}
            </ol>
            
            <button className='hdb-news-carousel__next' disabled={data.length <= 1}>
              <FaChevronRight />
            </button>
          </>
          :
          null
        }
      </div>
    </>
  );
};

export default wrapper(NewsCarousel, {
});

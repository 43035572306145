import React, { useEffect, useRef } from 'react';
import {connect} from 'react-redux';

import PropTypes from 'prop-types';

import AuthProvider from 'modules/Auth/fragments/AuthProvider';

import { AppRouter } from './router/AppRouter';
import { addGoogleAnalytics } from '../../helpers/googleAnalytics';
import { useLocation, useNavigate } from 'react-router-dom';
import { authUseCases } from '../../services/providers/auth';

const App = ({ role, isAuthenticated, meFailed, isWaitingGovBrLogout }) => {

  const location = useLocation();
  
  const navigate = useNavigate();
  
  const lastHash = useRef('');

  const init = () => {
    addGoogleAnalytics();
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const redirectUri = authUseCases.getLoginRedirect();
      if (redirectUri && window.env.LOGIN_ENABLED) {
        navigate(redirectUri);
      }
    }
  }, [isAuthenticated]);
  
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document.getElementById(lastHash.current)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  return (
    <AuthProvider>
      <AppRouter isAuthenticated={isAuthenticated} role={role} meFailed={meFailed} isWaitingGovBrLogout={isWaitingGovBrLogout} />
    </AuthProvider>
  );
};

App.defaultProps = {
  role: undefined,
  isAuthenticated: false
};

App.propTypes = {
  role: PropTypes.string,
  isAuthenticated: PropTypes.bool
};

const mapState = state => ({
  role: state.auth.role,
  isAuthenticated: state.auth.isAuthenticated,
  meFailed: state.auth.meFailed,
  isWaitingGovBrLogout: state.auth.isWaitingGovBrLogout
});

export default connect(mapState)(App);

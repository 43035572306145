export const Tooltip = ({icon, information}) => (
  <div className='hdb-tooltip'>
    <span className="hdb-tooltip__icon">
      <i className={'hdb-icon ' + icon} />
    </span>
    <div className="hdb-tooltip__info">
      <p>{information}</p>
    </div>
  </div>
)

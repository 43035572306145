/* eslint-disable react/prop-types */
import React from 'react';

import wrapper from 'components/Wrapper';

import './style.scss';
import PropTypes from 'prop-types';
import ContentAccordion from './fragments/ContentAccordion';

const CourseContent = ({ data }) => {

  return (
    <div className='hdb-course-content'>
      <strong className='hdb-course-content__title'>
        {data?.tituloConteudo}
      </strong>

      <div className='hdb-course-content__list'>
        { data?.conteudo?.map(item => (
          <ContentAccordion key={`content-${item.id}`} data={item}></ContentAccordion>
        ))}
      </div>
      
      <div className='hdb-course-content__download'>
        <a className='hdb-button hdb-button--primary' href={`/assets/json${data?.botaoDownloadCronograma?.arquivo?.data?.attributes?.url}`} target='_blank'>
          {data?.botaoDownloadCronograma?.textoBotao}
        </a>
      </div>
    </div>
  );
};

CourseContent.defaultProps = {
  data: {}
};

CourseContent.propTypes = {
  data: PropTypes.object
};

export default wrapper(CourseContent, {
  namespace: 'CONTENT_ACCORDION'
});

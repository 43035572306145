import React from 'react';

import PropTypes from 'prop-types';

import { getAssetUrl } from '../../../helpers/strapiUtils';

import './style.scss';
import TitleSubtitleButton from "../TitleSubtitleButton";

function Banner({ data, align, imageFull, isRegisterButton }) {

  return (
    <div className={`hdb-banner ${imageFull ? 'hdb-banner--full': ''}`}>
      <div className='container'>
        <div className='hdb-banner__text'>
          <TitleSubtitleButton title={data?.titulo} subtitle={data?.subtitulo} buttonText={data?.textoBotao}  buttonLink={data?.linkBotao} align={align} color='light' isRegisterButton={isRegisterButton} />
        </div>
        { data?.imagem && !imageFull
          ?
          <img src={getAssetUrl(data?.imagem)} className='hdb-banner__image' />
          :
          null
        }
      </div>

      { data?.imagem && imageFull
        ?
        <img src={getAssetUrl(data?.imagem)} className='hdb-banner__image' />
        :
        null
      }
    </div>
  );
}

Banner.defaultProps = {
  data: {},
  align: 'left',
  imageFull: false,
  isRegisterButton: false
};

Banner.propTypes = {
  data: PropTypes.object,
  align: PropTypes.string,
  imageFull: PropTypes.bool,
  isRegisterButton: PropTypes.bool
};

export default Banner;

export const isImage = (fileUrl) => {
  const imageFormats = ['.jpeg', '.jpg', '.png', '.gif', '.svg', '.tiff', '.ico', '.dvu'];
  const fileExtesion = getFileExtesion(fileUrl);
  return imageFormats.includes(`.${fileExtesion}`);
}

export const isVideo = (fileUrl) => {
  const videoFormats =['.mpeg', '.mpg', '.mp4', '.qtff', '.wmv', '.avi', '.flv'];
  const fileExtesion = getFileExtesion(fileUrl);
  return videoFormats.includes(`.${fileExtesion}`);
}

export const isDocument = (fileUrl) => {
  const fileExtesion = getFileExtesion(fileUrl);
  return !isImage(fileExtesion) && !isVideo(fileExtesion);
}

export const getFileExtesion = (fileUrl) => {
  return fileUrl.split(/[#?]/)[0].split('.').pop().trim();
}
import React, { useEffect, useState } from 'react';

import wrapper from 'components/Wrapper';

import { publicContentService  } from '../../services/providers/public-content';
import FormLoader from '../../components/Form/fragments/FormLoader';

import './style.scss';
import { ABOUT_TRAINING_ROUTES } from './constants/routes';
import TitleSubtitleButton from '../../components/Strapi/TitleSubtitleButton';
import TextAndImageBlock from '../../components/Strapi/TextAndImageBlock';
import FeatureContent from './fragments/FeatureContent';
import CourseContent from './fragments/CourseContent';
import LevelsContent from './fragments/LevelsContent';
import Breadcrumb from '../../components/Strapi/Breadcrumb';

const AboutTraining = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [breadcrumb] = useState([{
    name: 'Formação',
    link: '/formacao'
  }]);

  const init = () => {
    getData();
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await publicContentService.aboutTraining();
      const content = await response.json();
      if (content.data) {
        setData(content.data.attributes);
      }
      hideLoader();
    } catch (e) {
      hideLoader();
      console.error(e);
    }
  };
  
  const hideLoader = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div className='hdb-about-training'>
      {isLoading ? <FormLoader /> : null}

      <div className='hdb-blue-gradient-container'>
        <Breadcrumb routes={breadcrumb} />
        
        <div className='container'>
          <FeatureContent data={data?.blocoSuperior} />
          
          <TextAndImageBlock data={data?.bloco01} imgAuto={true} />
          
          <CourseContent data={data} />
          
          <LevelsContent data={data} />
          
          <TextAndImageBlock data={data?.bloco02} imgAuto={true} />

          <div className='hdb-about-training__bottom-text'>
            <TitleSubtitleButton title={data?.tituloCadastro} subtitle={data?.textoCadastro} buttonText={data?.textoBotaoCadastro} align='center' size='xl' isRegisterButton={true} />  
          </div>
        </div>
      </div>
    </div>
  );
};

export default wrapper(AboutTraining, {
  route: { path: ABOUT_TRAINING_ROUTES.ABOUT_TRAINING }
});
